import { timeFormats } from './../../../../shared/utils/date';
import dayjs from "dayjs";
import { JsonParser } from "../../../../shared/types/parser";
import { DailyInterval } from "../types/daily-interval";

export const parserDailyInerval: JsonParser<DailyInterval> = {
    toJson: (value) => ({
        "start": !!value.start ? dayjs(value.start, timeFormats).format("HH:mm:ss") : '',
        "end": !!value.end ? dayjs(value.end, timeFormats).format("HH:mm:ss") : '',
        "daysOfWeek": value.daysOfWeek,
    }),

    fromJson: (json) => ({
        start: !!json['start'] ? dayjs(json['start'], timeFormats).toDate().toLocaleTimeString() : '',
        end: !!json['end'] ? dayjs(json['end'], timeFormats).toDate().toLocaleTimeString() : '',
        daysOfWeek: json['daysOfWeek']
    })
}