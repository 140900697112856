import { FormikHelpers } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import DeleteAction from "../../../../common/components/actions/delete-action-button/DeleteAction";
import MutationResultHandler from "../../../../common/components/handlers/MutationResultHandler";
import QueryResultHandler from "../../../../common/components/handlers/QueryResultHandler";
import { useDeleteCalendar } from "../../api/useDeleteCalendar";
import { useGetCalendar } from "../../api/useGetCalendar";
import { useUpdateCalendar } from "../../api/useUpdateCalendar";
import { Calendar } from "../../types/calendar";
import CalendarForm from "./CalendarForm";
import cardStyles from '../../../../../theme/styles/Card.module.css';


interface Props {
    calendarId: string;
}

function UpdateCalendarForm(props: Props) {
    const { calendarId } = props;

    const queryClient = useQueryClient();
    const [, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    const updateCalendarResult = useUpdateCalendar()
    const deleteCalendarResult = useDeleteCalendar();

    const getCalendarResult = useGetCalendar(props.calendarId);

    const onDeleteCalendar = useCallback(() => {
        deleteCalendarResult.mutate(calendarId, {
            onSuccess: () => {
                queryClient.invalidateQueries(['calendars/getAll']);
                setSearchParams('');
            }
        })
    }, [deleteCalendarResult, calendarId, queryClient, setSearchParams])


    const onUpdateFormSubmit = useCallback((data: Calendar, helpers: FormikHelpers<Calendar>) => {
        updateCalendarResult.mutate(data, {
            onSuccess: () => {
                queryClient.invalidateQueries(['calendars/getAll']);
                queryClient.invalidateQueries(['calendars/get']);
            }
        })
    }, [updateCalendarResult, queryClient])

    return (
        <div className={cardStyles.card}>
            <div className={cardStyles.card_content}>
                <QueryResultHandler result={getCalendarResult}>
                    {({ data }) => (
                        <div>
                            <DeleteAction
                                label={t("common:deleteCalendar")}
                                onClick={onDeleteCalendar}
                            />
                            <MutationResultHandler result={deleteCalendarResult} />

                            <CalendarForm
                                initialState={data}
                                disabledActions={updateCalendarResult.isLoading}
                                onSubmit={onUpdateFormSubmit}
                            />
                            <MutationResultHandler result={updateCalendarResult} />
                        </div>
                    )}
                </QueryResultHandler>
            </div>
        </div>
    )
}

export default UpdateCalendarForm;