import { Icon } from "@fluentui/react";

interface Props {
    iconName: string;
}
function ListViewItemIcon(props: Props) {
    return (
        <Icon iconName={props.iconName} styles={{ root: { fontSize: '1.5rem', color: 'var(--theme-palette-fg-tertiary-accent)' } }} />
    )
}

export default ListViewItemIcon;