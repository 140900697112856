import { Checkbox, IconButton, TextField } from "@fluentui/react";
import { Field, FieldArray, FieldArrayRenderProps, FieldProps } from "formik";
import { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import FieldMetaError from "../../../../../error/components/field-meta-error/FieldMetaError";
import { getFieldMetaError } from "../../../../../error/utils/getFieldMetaError";
import { Calendar } from "../../../types/calendar";
import { DailyInterval } from "../../../types/daily-interval";
import styles from './DailyIntervals.module.css';

const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function DailyIntervals(props: FieldArrayRenderProps) {
    const { form, name, push, remove } = props;

    const { t } = useTranslation();

    const meta = form.getFieldMeta<DailyInterval[]>(name);

    const isWeekdaySelected = useCallback((values: string[], checkingValue: string) => {
        return values.includes(checkingValue)
    }, [])

    const toggleWeekdaySelection = useCallback((helpers: FieldArrayRenderProps, values: string[], checkingValue: string,) => {
        const isSelected = isWeekdaySelected(values, checkingValue);
        if (isSelected)
            helpers.remove(values.indexOf(checkingValue))
        else
            helpers.push(checkingValue)
    }, [isWeekdaySelected]);

    const addDailyInterval = useCallback(() => {
        push({
            daysOfWeek: [],
            start: '',
            end: '',
        })
    }, [push])

    const removeDailyInterval = useCallback((dailyInterval: DailyInterval) => {
        remove(meta.value.indexOf(dailyInterval));
    }, [remove, meta.value])

    return (
        <div className={styles.daily_intervals_content}>
            <FieldMetaError meta={meta} />
            <div className={styles.table_header}>
                <span className={styles.daily_intervals_label}>{t("common:dailyOpenningTimes")}</span>
                <IconButton
                    iconProps={{ iconName: 'Add' }}
                    title={t("common:add")}
                    ariaLabel="Add"
                    onClick={addDailyInterval}
                />
            </div>
            <div className={styles.table_container}>
                <table>
                    <thead>
                        <tr>
                            <th className={styles.weekday_column}>{t("common:sunday").charAt(0)}</th>
                            <th className={styles.weekday_column}>{t("common:monday").charAt(0)}</th>
                            <th className={styles.weekday_column}>{t("common:tuesday").charAt(0)}</th>
                            <th className={styles.weekday_column}>{t("common:wednesday").charAt(0)}</th>
                            <th className={styles.weekday_column}>{t("common:thursday").charAt(0)}</th>
                            <th className={styles.weekday_column}>{t("common:friday").charAt(0)}</th>
                            <th className={styles.weekday_column}>{t("common:saturday").charAt(0)}</th>
                            <th>{t("common:startTime")}</th>
                            <th>{t("common:endTime")}</th>
                            <th className={styles.action_column}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {meta.value.map((interval, dailyIntervalIndex) => (
                            <Fragment key={dailyIntervalIndex}>
                                <tr>
                                    <FieldArray name={`${name}.${dailyIntervalIndex}.daysOfWeek`}>
                                        {daysOfWeek => {
                                            const daysOfWeekValues = meta.value[dailyIntervalIndex].daysOfWeek;

                                            return (
                                                <Fragment>
                                                    {weekdays.map((weekday) => (
                                                        <td key={weekday} className={styles.weekday_column}>
                                                            <Checkbox
                                                                checked={isWeekdaySelected(daysOfWeekValues, weekday)}
                                                                onChange={(ev, checked) => toggleWeekdaySelection(daysOfWeek, daysOfWeekValues, weekday)}
                                                            />
                                                        </td>
                                                    ))}
                                                </Fragment>
                                            )
                                        }}
                                    </FieldArray>
                                    <td rowSpan={2}>
                                        <Field name={`${name}.${dailyIntervalIndex}.start`}>
                                            {({ field, meta }: FieldProps<string, Calendar>) => (
                                                <TextField placeholder="HH:mm" errorMessage={getFieldMetaError(meta)} {...field} />
                                            )}
                                        </Field>
                                    </td>
                                    <td rowSpan={2}>
                                        <Field name={`${name}.${dailyIntervalIndex}.end`}>
                                            {({ field, meta }: FieldProps<string, Calendar>) => (
                                                <TextField placeholder="HH:mm" errorMessage={getFieldMetaError(meta)} {...field} />
                                            )}
                                        </Field>
                                    </td>
                                    <td rowSpan={2}>
                                        <IconButton
                                            iconProps={{ iconName: 'Delete', styles: { root: { color: 'var(--theme-palette-error-default)' } } }}
                                            title={t("common:delete")}
                                            ariaLabel="Delete"
                                            onClick={() => removeDailyInterval(interval)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={7} className={styles.p_none}>
                                        <Field name={`${name}.${dailyIntervalIndex}.daysOfWeek`}>
                                            {({ field, meta }: FieldProps<number[], Calendar>) => (
                                                <FieldMetaError meta={meta} />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DailyIntervals;