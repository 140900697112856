import { useQuery } from 'react-query';
import { useHttpClient } from '../../../../shared/http/HttpClientProvider';
import { QueryOptions, QueryResult } from '../../../../shared/types/query';
import { Navigator } from '../../../common/types/navigator';
import { PageOptions } from '../../../common/types/page';
import { parserNavigator } from '../../../common/utils/parserNavigator';
import { User, userParser } from '../types/user';

interface UsersPageOptions extends PageOptions {
  showDeleted?: boolean;
}

export function useGetAllUsers(pageOptions: UsersPageOptions, options?: QueryOptions<Navigator<User>>): QueryResult<Navigator<User>> {
  const {page, pageSize, showDeleted} = pageOptions;
  const http = useHttpClient();

  return useQuery({
    ...options,
    queryKey: ['users/getAll', page, pageSize, showDeleted],
    queryFn: () => {
      return http.request({
        method: 'GET',
        url: 'Users',
        params: {
          page: page,
          perPage: pageSize,
          showDeleted,
        }
      }).then(response => parserNavigator.fromJson(response.data, userParser.fromJson))
    },
    keepPreviousData: true,
  })
}