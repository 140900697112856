import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";
import { parserCalendar } from "../utils/parserCalendar";
import { Calendar } from "../types/calendar";

export function useCreateCalendar(options?: MutationOptions<Calendar, Calendar>): MutationResult<Calendar, Calendar> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['calendars/create', http],
        mutationFn: (calendar: Calendar) => {
            return http.request({
                method: 'post',
                url: 'QueueCalendars',
                data: parserCalendar.toJson(calendar),
            }).then(result => parserCalendar.fromJson(result.data))
        },
    })
}