import { ReactElement, Suspense } from 'react';

import Loader from 'modules/common/components/loader/Loader';

interface Props {
  children: ReactElement
}

function SuspenseWrapper(props: Props) {
  return (
    <Suspense fallback={<Loader />}>
      {props.children}
    </Suspense>
  )
}
export default SuspenseWrapper;
