import { PropsWithChildren, useCallback, useState } from 'react';
import Provider, { createProviderToken, useProvider } from "../../../../shared/provider";

interface ITelerikVersionProvider {
  isR22017: boolean;
  isR22022: boolean;
  isVersionDetected: boolean;
  isScriptsLoaded: boolean;
  handleSetR22017: (val: boolean) => void;
  handleSetR22022: (val: boolean) => void;
  handleScriptsLoaded: (val: boolean) => void;
}

const providerToken = createProviderToken<ITelerikVersionProvider>('TelerikVersion');

export function useTelerikVersion(): ITelerikVersionProvider {
  return useProvider(providerToken);
}

function TelerikVersionProvider(props: PropsWithChildren<any>) {
  const [isR22017, setIsR22017] = useState<boolean>(false);
  const [isR22022, setIsR22022] = useState<boolean>(false);
  const [isVersionDetected, setIsVersionDetected] = useState<boolean>(false);
  const [isScriptsLoaded, setIsScriptsLoaded] = useState<boolean>(false);

  const handleSetR22017 = useCallback((value: boolean) => {
    setIsR22017(value);
    setIsVersionDetected(true);
  }, []);

  const handleSetR22022 = useCallback((value: boolean) => {
    setIsR22022(value);
    setIsVersionDetected(true);
  }, []);

  const handleScriptsLoaded = useCallback((value: boolean) => {
    setIsScriptsLoaded(value);
  }, []);

  return (
    <Provider
      provide={providerToken}
      value={{
        isVersionDetected,
        isScriptsLoaded, handleScriptsLoaded,
        isR22017, handleSetR22017,
        isR22022, handleSetR22022,
      }}
    >
      {props.children}
    </Provider>
  )
}

export default TelerikVersionProvider;
