import { FromJsonParser } from "../../../../shared/types/parser";

export interface ClientSettings {
    clientType: string;
    serializedSettings: string;
}


export interface ClientSettingsParser extends FromJsonParser<ClientSettings> {}

export const clientSettingsParser: ClientSettingsParser = {
    fromJson: (json) => {
        return {
            clientType: json['clientType'] ?? '',
            serializedSettings: json['serializedSettings'] ?? '',
        }
    }
}