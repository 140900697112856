import { useEffect, useRef, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { GetterFn, MapperFn } from "../types/common";

export type RxState<T> = BehaviorSubject<T>;

export function rxstate<T>(initialState: T): RxState<T> {
    return new BehaviorSubject<T>(initialState);
}

export function useRxState<T>(initial: T | GetterFn<T>): RxState<T> {
    const _rxstate = useRef<RxState<T>>();
    if(!_rxstate.current) {
        const _initialState = (typeof initial === 'function') ? (initial as GetterFn<T>)() : initial;
        return rxstate(_initialState);
    }

    return _rxstate.current;
}


export function useRxStateSelector<T, S>(rxstate: RxState<S>, mapper: MapperFn<S, T>): T {
    const mapperRef = useRef(mapper);

    const [state, setState] = useState<T>(() => mapper(rxstate.value));

    useEffect(() => {
        const subs = rxstate
            .asObservable()
            .pipe(
                map((state: S) => mapperRef.current(state)),
                distinctUntilChanged()
            )
            .subscribe((value: T) => setState(value))

        return () => {
            subs && subs.unsubscribe();
        }
    }, [rxstate]);

    return state;
}


const selectState = <S>(state: S) => state;
export function useRxStateValue<S>(rxlistenable: RxState<S>): S {
    return useRxStateSelector(rxlistenable, selectState);
}