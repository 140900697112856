import dayjs from "dayjs";
import { JsonParser } from "../../../../shared/types/parser";
import { AlwaysCloseOn } from "../types/always-closeon";

export const parserAlwaysCloseOn: JsonParser<AlwaysCloseOn> = {
    toJson: (value) => ({
        "date": dayjs(value.date).toISOString()
    }),

    fromJson: (json) => ({
        date: dayjs(json['date']).toDate()
    })
}