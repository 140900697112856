import { useSearchParams } from 'react-router-dom';
import { isNotEmptyString } from '../../../shared/validators/common';
import PageContentContainer from '../../common/components/page-content-container/PageContentContainer';
import CreateCalendarForm from './components/calendar-form/CreateCalendarForm';
import UpdateCalendarForm from './components/calendar-form/UpdateCalendarForm';
import CalendarList from './components/calendar-list/CalendarList';

interface Props {}

function Calendars(props: Props) {
    const [searchParams] = useSearchParams();

    const calendarId = searchParams.get("calendarId");

    return (
        <PageContentContainer 
            listSectionContent={<CalendarList selectedCalendarId={calendarId} />}
            formSectionContent={isNotEmptyString(calendarId) ? <UpdateCalendarForm calendarId={calendarId!} /> : <CreateCalendarForm />}
        />
    )
}

export default Calendars;