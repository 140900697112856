import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { isNotEmptyString } from '../../../../shared/validators/common';
import { AuthProviderConfig } from '../../discovery/types/auth-provider-config';
import { OidcUserManagerSettings } from '../types/oidc-user-manager-settings';

export function createOidcUserManagerFromAuthConfig(authInfoConfig: AuthProviderConfig): UserManager {
    return new UserManager({
        client_id: authInfoConfig?.clientId,
        client_secret: authInfoConfig?.clientSecret,
        authority: authInfoConfig?.authorityUri,
        redirect_uri: authInfoConfig?.redirectUri,
        silent_redirect_uri: authInfoConfig?.redirectUri,
        post_logout_redirect_uri: authInfoConfig.redirectUri,
        scope: isNotEmptyString(authInfoConfig.scopes) ? authInfoConfig.scopes : 'openid',
        response_type: 'code',
        loadUserInfo: false,
        monitorSession: false,
        client_authentication: isNotEmptyString(authInfoConfig?.clientSecret) ? 'client_secret_basic' : 'client_secret_post',
        automaticSilentRenew: true,
        stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
        extraQueryParams: authInfoConfig.additionalAuthorizationQueryArgs,
    })
}



export function createOidcUserManagerFromSettings(settings: OidcUserManagerSettings): UserManager {
    const { clientId, clientSecret, authorityUri, redirectUri, scope } = settings;

    return new UserManager({
        client_id: clientId,
        client_secret: clientSecret,
        authority: authorityUri,
        redirect_uri: redirectUri,
        scope: isNotEmptyString(scope) ? scope : 'openid',
        response_type: 'code',
        loadUserInfo: false,
        monitorSession: false,
        client_authentication: isNotEmptyString(clientSecret) ? 'client_secret_basic' : 'client_secret_post',
        automaticSilentRenew: false,
        stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    });
}