import { ActionButton } from '@fluentui/react';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import styles from './DeleteAction.module.css';

interface Props {
    iconName?: string;
    label: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    containerClassName?: string
}

function DeleteAction(props: Props) {
    const { iconName, label, onClick } = props;

    return (
        <div className={classNames(styles.action_container, props.containerClassName)}>
            <ActionButton 
                styles={{ root: { color: 'var(--theme-palette-error-default)' }, icon: { color: 'var(--theme-palette-error-default)' } }} 
                iconProps={{ iconName: iconName ?? "Delete" }} 
                onClick={onClick}
            >
                {label}
            </ActionButton>
        </div>
    )
}

export default DeleteAction;