import { ReactNode, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
//
// import getErrorByCode from 'modules/error/utils/errorsList';
// import { notify } from 'modules/common/utils/notification';

interface Props {
  children: ReactNode,
}

const ReactQueryClientProvider = (props: Props) => {
  const { children } = props;
  // const { t } = useTranslation();

  const queryClient = useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        refetchOnWindowFocus: false,
      },
      mutations: {
        retry: 1,
      }
    },
    // mutationCache: new MutationCache({
    //   onError: (error) => notify.error(t(getErrorByCode(error))),
    // }),
    // queryCache: new QueryCache({
    //   onError: (error) => notify.error(t(getErrorByCode(error))),
    // }),
  }), []);


  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};

export default ReactQueryClientProvider;