import { Modal } from "@fluentui/react";
import { Fragment, useMemo } from "react";
import { isNullOrUndefined } from "../../../../shared/validators/common";
import { parserCommonError } from "../../utils/parserCommonError";
import styles from './ErrorViewer.module.css';

interface Props {
    error: any;
    onDismiss: () => void;
}

function ErrorModal(props: Props) {
    const { error, onDismiss } = props;

    const _error = useMemo(() => parserCommonError.parseError(error), [error])

    return (
        <Fragment>
            {!isNullOrUndefined(_error) && (
                <Modal
                    isOpen={true}
                    isBlocking={false}
                    onDismiss={onDismiss}
                    allowTouchBodyScroll={true}
                    isClickableOutsideFocusTrap={true}
                >
                    <div className={styles.error_details}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles.error_field_name}>Message</td>
                                    <td className={styles.error_field_value}>{_error!.message}</td>
                                </tr>
                                {_error!.details && Object.keys(_error!.details).map((key, index) => _error!.details[key] && (
                                    <tr key={key}>
                                        <td className={styles.error_field_name}>{key}</td>
                                        <td className={styles.error_field_value}>{_error!.details[key]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal>
            )}
        </Fragment>
    )
}

export default ErrorModal;