import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { parserNavigator } from "../../../common/utils/parserNavigator";

import { QueryOptions, QueryResult } from "../../../../shared/types/query";
import { parserCalendar } from "../utils/parserCalendar";
import { Calendar } from "../types/calendar";
import { Navigator } from "../../../common/types/navigator";

export function useGetAllCalendars(options?: QueryOptions<Navigator<Calendar>>): QueryResult<Navigator<Calendar>> {
    const http = useHttpClient();

    return useQuery<Navigator<Calendar>>({
        ...options,
        queryKey: ['calendars/getAll', http],
        queryFn: () => {
            return http.request({
                method: 'get',
                url: 'QueueCalendars',
            }).then(result => parserNavigator.fromJson(result.data, parserCalendar.fromJson))
        },
    })
}