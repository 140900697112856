import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import styles from './FormActionButtons.module.css';

interface Props {
    saveButtonTitle?: string;
}

function FormActionButtons(props: Props) {
    const { t } = useTranslation();

    const formik = useFormikContext();

    return (
        <div className={styles.action_buttons}>
            <PrimaryButton
                disabled={!formik.dirty}
                type={formik.dirty ? 'submit' : 'button'}
                text={props.saveButtonTitle ?? t("common:save")}
                allowDisabledFocus
            />
            <DefaultButton
                disabled={!formik.dirty}
                type={formik.dirty ? 'reset' : 'button'}
                text={t("common:reset")}
                allowDisabledFocus
            />
        </div>
    )
}

export default FormActionButtons;