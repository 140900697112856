import { JsonParser } from "../../../../shared/types/parser";
import { isNullOrUndefined } from "../../../../shared/validators/common";
import { parserSelf } from "../../../common/utils/parserSelf";
import { Calendar } from "../types/calendar";
import { parserAlwaysCloseOn } from "./parserAlwaysCloseOn";
import { parserDailyInerval } from "./parserDailyInterval";

export const parserCalendar: JsonParser<Calendar> = {
    toJson: (value) => ({
        "id": value.id,
        "name": value.name,

        "dailyIntervals": isNullOrUndefined(value.dailyIntervals) 
            ? [] 
            : value.dailyIntervals.map(item => parserDailyInerval.toJson(item)),

        "alwaysCloseOn": isNullOrUndefined(value.alwaysCloseOn)
            ? []
            : value.alwaysCloseOn.map(item => parserAlwaysCloseOn.toJson(item)),        
    }),


    fromJson: (json) => ({
        ...parserSelf.fromJson(json),

        id: json['id'],
        name: json['name'] ?? '',
        dailyIntervals: isNullOrUndefined(json['dailyIntervals'])
            ? []
            : (json['dailyIntervals'] as Array<any>).map(item => parserDailyInerval.fromJson(item)),
        alwaysCloseOn: isNullOrUndefined(json['alwaysCloseOn'])
            ? []
            : (json['alwaysCloseOn'] as Array<any>).map(item => parserAlwaysCloseOn.fromJson(item))
    })
}