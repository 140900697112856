import { JsonParser } from './../../../../../shared/types/parser';
import { ElementCompact, js2xml, xml2js } from "xml-js";
import { GetterFn } from "../../../../../shared/types/common";
import { XmlParser } from "../../../../../shared/types/parser";

export interface GoogleContactTaskConfig {
    sourceSystem: string;
    clientId: string;
    clientSecret: string;
    deletePreviousImport: boolean;
    serializedToken: string;
}


const _empty = (): GoogleContactTaskConfig => {
    return {
        sourceSystem: '',
        clientId: '',
        clientSecret: '',
        deletePreviousImport: false,
        serializedToken: '',
    }
}

const _fromJson = (json: Record<string, any>): GoogleContactTaskConfig => {
    return {
        sourceSystem: json['sourceSystem'],
        clientId: json['clientId'],
        clientSecret: json['clientSecret'],
        deletePreviousImport: json['deletePreviousImport'],
        serializedToken: json['serializedToken']
    }
}

const _toJson = (config: GoogleContactTaskConfig): Record<string, any> => {
    return {
        "sourceSystem": config.sourceSystem,
        "clientId": config.clientId,
        "clientSecret": config.clientSecret,
        "deletePreviousImport": config.deletePreviousImport,
        "serializedToken": config.serializedToken
    }
}

const _fromXml = (xml: string): GoogleContactTaskConfig => {
    const obj: ElementCompact = xml2js(xml, { compact: true });
    if(obj.GoogleContactConfig) {
        const config = obj.GoogleContactConfig
        return {
            sourceSystem: config.SourceSystem?._text ?? '',
            clientId: config.ClientId?._text ?? '',
            clientSecret: config.ClientSecret?._text ?? '',
            deletePreviousImport: config.DeletePreviousImport?._text === 'true',
            serializedToken: config.SerializedToken?._text ?? '',
        }
    }
    return _empty();
}

const _toXml = (config: GoogleContactTaskConfig): string => {
    return js2xml({
        GoogleContactConfig: {
            _attributes: {
                "xmlns:i": "http://www.w3.org/2001/XMLSchema-instance",
                "xmlns": "http://schemas.datacontract.org/2004/07/Pridis.ConnecsyEnterprise.Imports.GoogleContactImport.Configuration",
            },
            SourceSystem: { 
                _text: config.sourceSystem,
                _attributes: {
                    "xmlns": "http://schemas.datacontract.org/2004/07/Pridis.ConnecsyEnterprise.AttendantSharedLib.Scheduler"
                }
            },
            ClientId: { _text: config.clientId },
            ClientSecret: { _text: config.clientSecret },
            DeletePreviousImport: { _text: config.deletePreviousImport },
            SerializedToken: { _text: config.serializedToken}
        }
    }, { compact: true })
}


export interface GoogleContactTaskConfigParser extends XmlParser<GoogleContactTaskConfig>, JsonParser<GoogleContactTaskConfig> {
    empty: GetterFn<GoogleContactTaskConfig>;
}

export const googleContactTaskConfigParser: GoogleContactTaskConfigParser = {
    empty: _empty,
    toXml: _toXml,
    fromXml: _fromXml,
    fromJson: _fromJson,
    toJson: _toJson,
}