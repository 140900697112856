import { PrimaryButton } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useLogout } from "../../../core/auth/api/useLogout";
import styles from './UnauthorizedUserError.module.css';

interface Props { }

function UnauthorizedUserError(props: Props) {
    const [t] = useTranslation();
    const { forceLogout } = useLogout();

    return (
        <div className={styles.error_container}>
            <p className={styles.error_message}>{t("validation:unauthorizedUser")}</p>
            <PrimaryButton
                text={t("common:signout")}
                allowDisabledFocus
                onClick={forceLogout}
            />
        </div>
    )
}

export default UnauthorizedUserError;