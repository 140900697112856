import { ReactNode } from "react";
import Provider, { createProviderToken, useProvider } from "../../../shared/provider";
import QueryResultHandler from "../../common/components/handlers/QueryResultHandler";
import discoveryApi from "./api";
import { AuthProviderConfig } from "./types/auth-provider-config";

const authProviderConfigProviderToken = createProviderToken<AuthProviderConfig>('authProviderConfigProvider');

export function useAuthProviderConfig(): AuthProviderConfig {
    return useProvider(authProviderConfigProviderToken);
}

interface Props {
    children?: ReactNode;
}

function AuthProviderConfigProvider(props: Props) {
    const getAuthProviderConfigResult = discoveryApi.useGetAuthProviderConfig();

    return (
        <QueryResultHandler result={getAuthProviderConfigResult}>
            {({ data }) => (
                <Provider provide={authProviderConfigProviderToken} value={data}>
                    {props.children}
                </Provider>
            )}
        </QueryResultHandler>
    )
}

export default AuthProviderConfigProvider