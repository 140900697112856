import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { useHttpClient } from '../../../../shared/http/HttpClientProvider';
import { MutationOptions, MutationResult } from '../../../../shared/types/query';

export function useRestoreUser(options?: MutationOptions<string, AxiosResponse>): MutationResult<string, AxiosResponse> {
  const http = useHttpClient();

  return useMutation({
    ...options,
    mutationKey: ['users/restore', http],
    mutationFn: (id) => {
      return http.request({
        method: 'POST',
        url: `Users/${id}/restore`,
      })
    },
  })
}
