import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { isNullOrUndefined } from "../../../../shared/validators/common";
import { QueryOptions, QueryResult } from "../../../../shared/types/query";
import { parserCalendar } from "../utils/parserCalendar";
import { Calendar } from "../types/calendar";

export function useGetCalendar(id?: string, options?: QueryOptions<Calendar>): QueryResult<Calendar> {
    const http = useHttpClient();

    return useQuery<Calendar>({
        ...options,
        queryKey: ['calendars/get', http, id],
        queryFn: () => {
            return http.request({
                method: 'get',
                url: `QueueCalendars/${id}`,
            }).then(result => parserCalendar.fromJson(result.data))
        },
        enabled: !isNullOrUndefined(id),
    })
}