import { DatePicker, defaultDatePickerStrings, IconButton } from "@fluentui/react";
import dayjs from "dayjs";
import { Field, FieldArrayRenderProps, FieldProps } from "formik";
import { t } from "i18next";
import { useCallback } from "react";
import FieldMetaError from "../../../../../error/components/field-meta-error/FieldMetaError";
import { getFieldMetaError } from "../../../../../error/utils/getFieldMetaError";
import { AlwaysCloseOn } from "../../../types/always-closeon";
import { Calendar } from "../../../types/calendar";
import styles from './AlwaysClosedOn.module.css';

function AlwaysClosedOn(props: FieldArrayRenderProps) {
    const { name, form, push, remove } = props;

    const meta = form.getFieldMeta<AlwaysCloseOn[]>(name);

    const addDate = useCallback(() => {
        push({
            date: dayjs().toDate()
        })
    }, [push])

    const removeDate = useCallback((date: AlwaysCloseOn) => {
        remove(meta.value.indexOf(date));
    }, [meta.value, remove])

    return (
        <div className={styles.table_container}>
            <FieldMetaError meta={meta} />
            <div className={styles.table_header}>
                <span className={styles.table_header_label}>{t("common:alwaysClosedOn")}</span>
                <IconButton
                    iconProps={{ iconName: 'Add' }}
                    title={t("common:add")}
                    ariaLabel="Add"
                    onClick={addDate}
                />
            </div>
            <div className={styles.table_content}>
                <table>
                    <thead>
                        <tr>
                            <th className={styles.date_column}>{t("common:date")}</th>
                            <th className={styles.action_column}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {meta.value.map((date, index) => (
                            <tr key={index}>
                                <td>
                                    <Field name={`${name}.${index}.date`}>
                                        {({ meta, field }: FieldProps<Date, Calendar>) => (
                                            <DatePicker
                                                textField={{ errorMessage: getFieldMetaError(meta) }}
                                                minDate={dayjs().toDate()}
                                                value={meta.value}
                                                onSelectDate={(val) => field.onChange({ target: { name: field.name, value: val } })}
                                                onBlur={field.onBlur}
                                                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                                strings={defaultDatePickerStrings}
                                            />
                                        )}
                                    </Field>
                                </td>
                                <td>
                                    <IconButton
                                        iconProps={{ iconName: 'Delete', styles: { root: { color: 'var(--theme-palette-error-default)' } } }}
                                        title={t("common:delete")}
                                        ariaLabel="Delete"
                                        onClick={() => removeDate(date)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AlwaysClosedOn;



