import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from '../../../../shared/types/query';

export function useDeleteSession(options?: MutationOptions<void, AxiosResponse<any>>): MutationResult<void, AxiosResponse<any>> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['sessions/delete', http],
        mutationFn: () => {
            return http.request({
                method: 'delete',
                url: '',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                },
                params: {
                    forcedLogoff: true
                },
                data: []
            })
        },
    })
}