import { ReactNode, useCallback, useMemo } from "react";
import Provider, { createProviderToken, useProvider } from "../../../../shared/provider";
import {  SetterFn, VoidCallback } from "../../../../shared/types/common";
import { RxState, useRxState } from "../../../../shared/rxstate";
import { useStorage } from "../../storage/storage";

const providerToken = createProviderToken<UseBrandReturn>('brand');

export interface UseBrandReturn {
    brand: RxState<string | null>;
    setBrand: SetterFn<string>;
    removeBrand: VoidCallback;
}

export function useBrand(): UseBrandReturn {
    return useProvider(providerToken)
}

interface Props {
    children?: ReactNode;
}

function BrandProvider(props: Props) {
    const storage = useStorage();

    const brandRxState = useRxState<string | null>(storage.getBrand)

    const setBrand = useCallback((brandId: string) => {
        brandRxState.next(brandId)
        storage.saveBrand(brandId)
    }, [storage, brandRxState])

    const removeBrand = useCallback(() => {
        brandRxState.next(null);
        storage.removeBrand();
    }, [storage, brandRxState])

    const result: UseBrandReturn = useMemo(() => {
        return {
            brand: brandRxState,
            setBrand: setBrand,
            removeBrand: removeBrand,
        }
    }, [brandRxState, setBrand, removeBrand])

    return (
        <Provider provide={providerToken} value={result}>
            {props.children}
        </Provider>
    )
}

export default BrandProvider;
