import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryOptions, QueryResult } from "../../../../shared/types/query";
import { ServerConfiguration, useServerConfigurationParser } from "../types/server-configuration";

export const KEY_GET_SERVER_CONFIGURATION = 'configuration/getServerConfiguration';

export function useGetServerConfiguration(options?: QueryOptions<ServerConfiguration>): QueryResult<ServerConfiguration> {
    const http = useHttpClient();
    const serverConfigParser = useServerConfigurationParser();

    return useQuery<ServerConfiguration>({
        ...options,
        queryKey: [KEY_GET_SERVER_CONFIGURATION, http],
        queryFn: () => {
            return http.request<ServerConfiguration>({
                method: 'get',
                url: `ServerConfiguration`,
            }).then(result => serverConfigParser.fromJson(result.data))
        },
    })
}