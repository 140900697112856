import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";


i18next
    .use(Backend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        ns: ['common', 'validation', 'errors'],
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        detection: {
            order: ['localStorage', 'sessionStorage' /* 'querystring', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain' */],

            // keys or params to lookup language from
            lookupQuerystring: 'lang',
            lookupCookie: 'lang',
            lookupLocalStorage: 'lang',
            lookupSessionStorage: 'lang',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'sessionStorage'],
            excludeCacheFor: ['cimode'],
        }
    })


export default i18next;