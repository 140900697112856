import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult, QueryOptions, QueryResult } from "../../../../shared/types/query";
import { isNullOrUndefined } from '../../../../shared/validators/common';
import { Navigator } from "../../../common/types/navigator";
import { parserNavigator } from '../../../common/utils/parserNavigator';
import { Subscription } from "../types/subscription";

export function useGetAllSubscriptions(options?: QueryOptions<Navigator<Subscription>>): QueryResult<Navigator<Subscription>> {
    const http = useHttpClient();

    return useQuery<Navigator<Subscription>>({
        ...options,
        queryKey: ['subscriptions/getAll', http],
        queryFn: () => {
            return http.request({
                method: 'get',
                url: 'Subscriptions',
            }).then(result => parserNavigator.fromJson(result.data, (json) => json as Subscription))
        },
    })
}


export function useGetSubscription(id?: string, options?: QueryOptions<Subscription>): QueryResult<Subscription> {
    const http = useHttpClient();

    return useQuery<Subscription>({
        ...options,
        queryKey: ['subscriptions/get', http, id],
        queryFn: () => {
            return http.request({
                method: 'get',
                url: `Subscriptions/${id}`,
            }).then(result => result.data as Subscription)
        },
        enabled: !isNullOrUndefined(id),
    })
}


export function usePostNewSubscription(options?: MutationOptions<void, AxiosResponse<any>>): MutationResult<void, AxiosResponse<any>> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['subscriptions/new', http],
        mutationFn: () => {
            return http.request({
                method: 'post',
                url: 'Subscriptions',
                data: {
                    resourceFilters: "TaskScheduler,AttendantState",
                    subscriptionType: "WebSocket"
                }
            })
        },
    })
}


export function useDeleteSubscription(options?: MutationOptions<string, AxiosResponse>): MutationResult<string, AxiosResponse> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['subscriptions/delete', http],
        mutationFn: (id: string) => {
            return http.request({
                method: 'delete',
                url: `Subscriptions/${id}`,
            })
        },
    })
}