import { QueryOptions, QueryResult } from "../../../../shared/types/query";
import { ServerConfiguration } from "../types/server-configuration";
import { useGetServerConfiguration } from "./useGetServerConfiguration";

export interface ConfigApi {
    useGetServerConfiguration(options?: QueryOptions<ServerConfiguration>): QueryResult<ServerConfiguration>;
}

const _createConfigApi = (): ConfigApi => {
    return {
        useGetServerConfiguration,
    }
}

let _api: ConfigApi;
export const useConfigApi = (): ConfigApi => {
    if (_api === undefined)
        _api = _createConfigApi();

    return _api;
}
