import { ActionButton } from '@fluentui/react';
import { MouseEventHandler } from 'react';

interface Props {
    iconName: string;
    label: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

function AddAction(props: Props) {
    const { iconName, label, onClick } = props;

    return (
        <ActionButton iconProps={{ iconName: iconName }} onClick={onClick}>
            {label}
        </ActionButton>
    )
}

export default AddAction;