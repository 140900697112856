import { PropsWithChildren, useEffect, useState } from "react";
import Provider, { createProviderToken, useProvider } from "../../../shared/provider";
import Loader from "../../common/components/loader/Loader";
import { Subscription } from "./types/subscription";

const subscriptionInstanceProviderToken = createProviderToken<Subscription>('subscriptionInstance');
const websocketProviderToken = createProviderToken<WebSocket>('websocket');

export function useSubscriptionInstance(): Subscription {
    return useProvider(subscriptionInstanceProviderToken);
}

export function useWebSocket(): WebSocket {
    return useProvider(websocketProviderToken);
}


interface Props {
    subscription: Subscription;
}

function SubscriptionProvider(props: PropsWithChildren<Props>) {
    const { subscription } = props;

    const [activeSocket, setActiveSocket] = useState<WebSocket>();

    useEffect(() => {
        const socket = new WebSocket(subscription.wsRequestUri)
        console.log("[WebSocket] initialized");

        socket.onopen = (event: Event) => {
            socket.send(subscription.wsRequestToken);
            setActiveSocket(socket);
        }

        return () => {
            socket.close();
            console.log("[WebSocket] closed");
        }
    }, [subscription])

    if(!activeSocket) return <Loader />

    return (
        <Provider provide={subscriptionInstanceProviderToken} value={subscription}>
            <Provider provide={websocketProviderToken} value={activeSocket}>
                {props.children}
            </Provider>
        </Provider>
    )
}

export default SubscriptionProvider;