import { FromJsonParser } from "../../../../shared/types/parser";
import { parserSelf } from "../../../common/utils/parserSelf";
import { parserCalendar } from "../../calendars/utils/parserCalendar";
import { Queue } from "../types/queue";

export const parserQueue: FromJsonParser<Queue> = {
    fromJson: (json) => {
        return {
            ...parserSelf.fromJson(json),
            id: json['id'],
            queueId: json['queueId'],
            type: json['type'],
            name: json['name'],
            priorityLevel: json['priorityLevel'],
            overridePriorityLevel: json['overridePriorityLevel'],
            acceptCalls: json['acceptCalls'],
            overrideQueueOperationalStatus: json['overrideQueueOperationalStatus'],
            destinationOnClosed: json['destinationOnClosed'],
            maxQueueLength: json['maxQueueLength'],
            destinationOnQueueOverflow: json['destinationOnQueueOverflow'],
            destinationOnNoAttendantsAvailable: json['destinationOnNoAttendantsAvailable'],
            destinationOnManualRoute: json['destinationOnManualRoute'],
            maxWaittime: json['maxWaittime'],
            destinationOnMaxWaittime: json['destinationOnMaxWaittime'],
            calendar: json['calendar'] && parserCalendar.fromJson(json['calendar']),
            enableCallRecording: json['enableCallRecording'],
            callRouting: json['callRouting'],
        }
    }
}