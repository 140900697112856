import { ReactNode, useEffect, useMemo } from 'react';
import { PartialTheme, ThemeProvider as FluentUiThemeProvider } from '@fluentui/react';
import themeStyles from './Theme.module.css';
import classNames from 'classnames';
interface Props {
    children: ReactNode;
}

function ThemeProvider(props: Props) {
    const theme = useMemo(() => getFluentUiTheme(), [])

    useEffect(() => {
        document.body.className=themeStyles.app_theme
    }, [])

    return (
        <FluentUiThemeProvider className={classNames(themeStyles.app_theme, themeStyles.app_container)} theme={theme}>
            {props.children}
        </FluentUiThemeProvider>
    )
}

export default ThemeProvider;




function getFluentUiTheme(): PartialTheme {
    return {
        palette: {
            // themeDark: '',
            // themeDarkAlt: '',
            // themeDarker: '',
            // themeLight: '',
            // themeLighter: '',
            // themeLighterAlt: '',
            // themePrimary: '',
            // themeSecondary: '',
            // themeTertiary: '',
        },
        semanticColors: {

        },
    }
}