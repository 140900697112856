import { TextField } from "@fluentui/react";
import { FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { getFieldMetaError } from "../../../../../error/utils/getFieldMetaError";

function Name<FormValues>(props: FieldProps<string, FormValues>) {
    const { meta, field } = props;
    const [t] = useTranslation();

    return (
        <TextField
            label={t("common:name")}
            errorMessage={getFieldMetaError(meta)}
            {...field}
        />
    )
}

export default Name;