import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../common/components/loader/Loader";
import QueryErrorViewer from "../../../error/components/error-viewer/QueryErrorViewer";
import { googleContactTaskConfigParser } from "../../../feature/tasks/types/task-config/google-contact-task-config";
import { taskFormDataParser } from "../../../feature/tasks/types/task-form-data";
import { createOidcUserManagerFromSettings } from "../utils/utils";

interface Props { }

function GoogleAuthCallback(props: Props) {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { isError, error } = useQuery({
        queryKey: ['auth/google/callback', navigate, searchParams],
        retry: 0,
        queryFn: async () => {
            const state = searchParams.get('state');
            if (!state) throw new Error('State not found');
            const key = `oidc.${state}`;
            const settings = sessionStorage.getItem(key);
            if (!settings) throw new Error('Google auth settings not found');
            const parsedSettings = JSON.parse(settings);
            const userManager = createOidcUserManagerFromSettings({
                authorityUri: parsedSettings.authority,
                clientId: parsedSettings.client_id,
                clientSecret: parsedSettings.client_secret,
                redirectUri: parsedSettings.redirect_uri,
            })
            const user = await userManager.signinCallback();
            if (!user) throw new Error('User not found');

            const serializedToken = JSON.stringify({
                access_token: user.access_token,
                token_type: user.token_type ?? 'bearer',
                expires_in: user.expires_in,
                refresh_token: user.refresh_token,
                scope: user.scope,
                id_token: user.id_token,
                IssuedUtc: dayjs().toISOString()
            })

            const formValues = (user.state as any).values;
            const from = (user.state as any).location;

            await userManager.clearStaleState();
            await userManager.removeUser();

            if (formValues && from) {
                const parsedFormValues = taskFormDataParser.fromJson(formValues, googleContactTaskConfigParser);
                parsedFormValues.taskConfig.serializedToken = serializedToken;

                navigate(from, { state: parsedFormValues })
            }

        }
    })

    if (isError) return <QueryErrorViewer error={error} />
    return <Loader />
}

export default GoogleAuthCallback