import { useQuery } from 'react-query';
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryOptions, QueryResult } from '../../../../shared/types/query';
import { useBrandAuthConfig } from '../../configuration/components/BrandAuthConfigProvider';
import { ApiServerInfo } from '../types/api-server-info';


export function useGetApiServerInfo(options?: QueryOptions<ApiServerInfo>): QueryResult<ApiServerInfo> {
    const http = useHttpClient();
    const brandAuthConfig = useBrandAuthConfig();

    return useQuery({
        ...options,
        queryKey: ['discovery/apiServerInfo', http, brandAuthConfig],
        queryFn: () => {
            return http.request<ApiServerInfo>({
                method: 'get',
                url: '/.discovery/.apiserverinfo',
                params: {
                    brandId: brandAuthConfig.bid,
                }
            }).then(result => result.data)
        }
    })
}
