import { createContext, ReactNode, useContext } from 'react';
import { createHttpClient, HttpClient } from './http';

const HttpClientContext = createContext<HttpClient>(createHttpClient())

interface Props {
    httpClient: HttpClient;
    children?: ReactNode;
}

function HttpClientProvider(props: Props) {
    return (
        <HttpClientContext.Provider value={props.httpClient}>
            {props.children}
        </HttpClientContext.Provider>
    )
}

export default HttpClientProvider;


export function useHttpClient(): HttpClient {
    return useContext(HttpClientContext);
}