import styles from './ErrorMessage.module.css';


interface Props {
    message?: string;
}

function ErrorMessage(props: Props) {
   return <p className={styles.error_message}>{props.message}</p>
}

export default ErrorMessage;