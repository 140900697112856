import { Fragment, PropsWithChildren, useMemo } from "react";
import Provider, { createProviderToken, useProvider } from "../../../../shared/provider";
import Loader from "../../../common/components/loader/Loader";
import QueryErrorViewer from "../../../error/components/error-viewer/QueryErrorViewer";
import userApi from "../../../feature/users/api";
import { UserRoles } from "../../../feature/users/types/user-role";
import { useServerConfiguration } from "../../configuration/components/ServerConfigurationProvider";
import { useSession } from "../../session/SessionProvider";
import { UserData } from "../types/user-data";

const userDataProviderToken = createProviderToken<UserData>('userData');

export function useUserData(): UserData {
    return useProvider(userDataProviderToken);
}

interface Props {}

function UserDataProvider(props: PropsWithChildren<Props>) {
    const sessionRx = useSession();
    const serverConfig = useServerConfiguration();

    const { isLoading, isError, error, isSuccess, data } = userApi.useGetUser(sessionRx.value.attendantUser?.id);

    const userData = useMemo<UserData | undefined>(() => {
        if(data && serverConfig) {
            return {
                user: data,
                permission: {
                    profiles: {
                        view: serverConfig.hasProfiles && [UserRoles.SystemAdmin, UserRoles.Supervisor].includes(data.userRole?.id!),
                    },
                    skills: {
                        view: serverConfig.agentsFeatures.skillsEnabled && [UserRoles.SystemAdmin, UserRoles.Supervisor].includes(data.userRole?.id!),
                    },
                    nrc: {
                        view: serverConfig.agentsFeatures.notReadyReasonsEnabled && [UserRoles.SystemAdmin, UserRoles.Supervisor].includes(data.userRole?.id!),
                    },
                    queues: {
                        view: !serverConfig.telephonyCapabilities.queueProviderCapabilities.connectorProvidesQueueRouting && [UserRoles.SystemAdmin].includes(data.userRole?.id!),
                    },
                    chatSettings: {
                        view: serverConfig.hasChat && [UserRoles.SystemAdmin].includes(data.userRole?.id!),
                    },
                    emailTemplates: {
                        view: [UserRoles.SystemAdmin, UserRoles.Supervisor].includes(data.userRole?.id!),
                    },
                    taskScheduler: {
                        view: [UserRoles.SystemAdmin].includes(data.userRole?.id!),
                    },
                    calendars: {
                        view: (serverConfig.telephonyCapabilities.queueProviderCapabilities.supportsQueueOpenClose || serverConfig.hasChat)
                          && [UserRoles.SystemAdmin].includes(data.userRole?.id!),
                    },
                    reports: {
                        view: [UserRoles.SystemAdmin, UserRoles.Supervisor, UserRoles.Attendant].includes(data.userRole?.id!),
                    },
                    contacts: {
                        view: [UserRoles.SystemAdmin].includes(data.userRole?.id!),
                    },
                    users: {
                        view: [UserRoles.SystemAdmin, UserRoles.Supervisor].includes(data.userRole?.id!),
                        fields: {
                            recordOutgoingCalls: serverConfig.telephonyCapabilities.recordingEnabled,
                            allowTemporaryRemovalFromQueue: serverConfig.telephonyCapabilities.queueProviderCapabilities.supportsQueueTemporaryUnassignment,
                            preferredExtension: serverConfig.telephonyCapabilities.requiresExtension,
                            directCallPriorityLevel: !serverConfig.telephonyCapabilities.queueProviderCapabilities.connectorProvidesQueueRouting,
                        }
                    },
                    cannedResponses: {
                        view: serverConfig.agentsFeatures.responseTemplatesEnabled,
                    },
                    recordings: {
                      view: serverConfig.serverFeatures.supportsRecordingPlayback,
                    },
                }
            }
        }
        return undefined;
    }, [serverConfig, data])


    return (
        <Fragment>
            {isLoading && <Loader />}
            {isError && <QueryErrorViewer error={error} />}
            {isSuccess && userData && (
                <Provider provide={userDataProviderToken} value={userData}>
                    {props.children}
                </Provider>
            )}
        </Fragment>
    )

}

export default UserDataProvider;