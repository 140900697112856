import { FromJsonFn, FromJsonParser } from "../../../../shared/types/parser";

export interface ServerConfiguration {
    serverVersion: string;
    sharedClientConfiguration: {
        customDataFields: Record<string, string>;
        customDataFieldTypes: Record<string, string>;
        customAttendantUserDataFields: Record<string, string>;
        callRoutingTypes: Record<string, string>;
    };
    reportingServiceUri: string;
    schedulerControlEnabled: boolean;
    telephonyCapabilities: {
        supportsMute: boolean,
        supportsDtmf: boolean,
        supportsBlindTransfer: boolean,
        supportsConsultiveTransfer: boolean,
        recordingEnabled: boolean,
        supportsSerialCall: boolean,
        supportsRedirectToAttendant: boolean,
        supportsForwarders: boolean,
        queueProviderCapabilities: {
            connectorProvidesQueueRouting: boolean,
            supportsAddQueue: boolean,
            supportsRemoveQueue: boolean,
            supportsEditQueue: boolean,
            supportsDefaultQueue: boolean,
            supportsQueueOpenClose: boolean,
            supportsQueueUserAssignment: boolean,
            supportsQueueTemporaryUnassignment: boolean,
            editableQueueFields: string;
        };
        supportsPhoneDeviceSelection: boolean,
        requiresExtension: boolean,
        requiresConnectorUsername: boolean,
        requiresConnectorPassword: boolean,
    };
    agentsFeatures: {
      chatEnabled: boolean;
      customerJourneyEnabled: boolean;
      skillsEnabled: boolean;
      notReadyReasonsEnabled: boolean;
      responseTemplatesEnabled: boolean,
    };
    serverFeatures: {
      supportsRecordingPlayback: boolean;
    },
    hasChat: boolean;
    validPhonenumberRegEx: string;
    hasProfiles: boolean;
    enableAgents: boolean;
}


export interface ServerConfigurationParser extends FromJsonParser<ServerConfiguration> {}

const _fromJson: FromJsonFn<ServerConfiguration> = (json) => {
    return {
        serverVersion: json?.serverVersion,
        sharedClientConfiguration: {
            customDataFields: json?.sharedClientConfiguration?.customDataFields,
            customDataFieldTypes: json?.sharedClientConfiguration?.customDataFieldTypes,
            customAttendantUserDataFields: json?.sharedClientConfiguration?.customAttendantUserDataFields,
            callRoutingTypes: json?.sharedClientConfiguration?.callRoutingTypes,
        },
        reportingServiceUri: json?.reportingServiceUri,
        schedulerControlEnabled: json?.schedulerControlEnabled,
        telephonyCapabilities: {
            supportsMute: json?.telephonyCapabilities?.supportsMute,
            supportsDtmf: json?.telephonyCapabilities?.supportsDtmf,
            supportsBlindTransfer: json?.telephonyCapabilities?.supportsBlindTransfer,
            supportsConsultiveTransfer: json?.telephonyCapabilities?.supportsConsultiveTransfer,
            recordingEnabled: json?.telephonyCapabilities?.recordingEnabled,
            supportsSerialCall: json?.telephonyCapabilities?.supportsSerialCall,
            supportsRedirectToAttendant: json?.telephonyCapabilities?.supportsRedirectToAttendant,
            supportsForwarders: json?.telephonyCapabilities?.supportsForwarders,
            queueProviderCapabilities: {
                connectorProvidesQueueRouting: json?.telephonyCapabilities?.queueProviderCapabilities?.connectorProvidesQueueRouting,
                supportsAddQueue: json?.telephonyCapabilities?.queueProviderCapabilities?.supportsAddQueue,
                supportsRemoveQueue: json?.telephonyCapabilities?.queueProviderCapabilities?.supportsRemoveQueue,
                supportsEditQueue: json?.telephonyCapabilities?.queueProviderCapabilities?.supportsEditQueue,
                supportsDefaultQueue: json?.telephonyCapabilities?.queueProviderCapabilities?.supportsDefaultQueue,
                supportsQueueOpenClose: json?.telephonyCapabilities?.queueProviderCapabilities?.supportsQueueOpenClose,
                supportsQueueUserAssignment: json?.telephonyCapabilities?.queueProviderCapabilities?.supportsQueueUserAssignment,
                supportsQueueTemporaryUnassignment: json?.telephonyCapabilities?.queueProviderCapabilities?.supportsQueueTemporaryUnassignment,
                editableQueueFields: json?.telephonyCapabilities?.queueProviderCapabilities?.editableQueueFields,
            },
            supportsPhoneDeviceSelection: json?.telephonyCapabilities?.supportsPhoneDeviceSelection,
            requiresExtension: json?.telephonyCapabilities?.requiresExtension,
            requiresConnectorUsername: json?.telephonyCapabilities?.requiresConnectorUsername,
            requiresConnectorPassword: json?.telephonyCapabilities?.requiresConnectorPassword,
        },
        hasChat: json?.hasChat,
        validPhonenumberRegEx: json?.validPhonenumberRegEx,
        hasProfiles: json?.hasProfiles,
        enableAgents: json?.hasProfiles,
        agentsFeatures: {
            notReadyReasonsEnabled: json?.agentsFeatures?.notReadyReasonsEnabled,
            skillsEnabled: json?.agentsFeatures?.skillsEnabled,
            customerJourneyEnabled: json?.agentsFeatures?.customerJourneyEnabled,
            chatEnabled: json?.agentsFeatures?.chatEnabled,
            responseTemplatesEnabled: !!json?.agentsFeatures?.responseTemplatesEnabled,
        },
        serverFeatures: {
            supportsRecordingPlayback: json?.telephonyCapabilities?.supportsRecordingPlayback,
        },
    }
}

const _createServerConfigurationParser = (): ServerConfigurationParser => {
    return {
        fromJson: _fromJson,
    }
}


let _parser: ServerConfigurationParser;
export const useServerConfigurationParser = (): ServerConfigurationParser => {
    if(_parser === undefined)
        _parser = _createServerConfigurationParser();
    
    return _parser;
}