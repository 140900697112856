import { UserManager } from "oidc-client-ts";
import { ReactNode, useMemo } from "react";
import Provider, { createProviderToken, useProvider } from "../../../../shared/provider";
import { createOidcUserManagerFromAuthConfig } from "../utils/utils";
import { useAuthProviderConfig } from "../../discovery/AuthProviderConfigProvider";

const oidcUserManagerProviderToken = createProviderToken<UserManager>('oidcUserManager');

export function useOidcUserManager(): UserManager {
    return useProvider(oidcUserManagerProviderToken);
}

interface Props {
    children?: ReactNode
}

function OidcUserManagerProvider(props: Props) {
    const authProviderConfig = useAuthProviderConfig();

    const userManager = useMemo(() => createOidcUserManagerFromAuthConfig(authProviderConfig!), [authProviderConfig])

    return (
        <Provider provide={oidcUserManagerProviderToken} value={userManager}>
            {props.children}
        </Provider>
    )
}

export default OidcUserManagerProvider;