import ErrorViewer from "./ErrorViewer";

interface Props {
    error: any;
    refetch?: (options: { throwOnError: boolean, cancelRefetch: boolean }) => void;
}

function QueryErrorViewer(props: Props) {
    return (
        <ErrorViewer error={props.error} />
    )
}

export default QueryErrorViewer