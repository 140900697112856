import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";
import { parserCalendar } from "../utils/parserCalendar";
import { Calendar } from "../types/calendar";

export function useUpdateCalendar(options?: MutationOptions<Calendar, Calendar>): MutationResult<Calendar, Calendar> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['calendars/update', http],
        mutationFn: (calendar: Calendar) => {
            return http.request({
                method: 'put',
                url: `QueueCalendars/${calendar.id}`,
                data: parserCalendar.toJson(calendar),
            }).then(result => parserCalendar.fromJson(result.data))
        },
    })
}