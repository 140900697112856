import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryOptions, QueryResult } from "../../../../shared/types/query";
import { isNotEmptyString } from "../../../../shared/validators/common";
import { User, userParser } from "../types/user";

export function useGetUser(id?: string, options?: QueryOptions<User>): QueryResult<User> {
    const http = useHttpClient();

    return useQuery({
        ...options,
        queryKey: ['users/get', http, id],
        queryFn: () => {
            return http.request({
                method: 'GET',
                url: `Users/${id}`
            }).then(response => userParser.fromJson(response.data))
        },
        enabled: isNotEmptyString(id)
    })
}