import { Context, createContext, PropsWithChildren, useContext, useMemo } from "react";

// eslint-disable-next-line
export interface ProviderToken<T> {
    readonly tag: string;
}

export function createProviderToken<T>(tag: string): ProviderToken<T> {
    return { tag: tag }
}

const ProviderContext: Context<Record<string, any>> = createContext({});
interface Props<T> {
    provide: ProviderToken<T>;
    value: T;
}
function Provider<T>(props: PropsWithChildren<Props<T>>) {
    const { provide, value } = props;
    const providers = useContext(ProviderContext);
    const mergedValue = useMemo(() => {
        return { ...providers, [provide.tag]: value };
    }, [providers, provide, value]);

    return (
        <ProviderContext.Provider value={mergedValue}>
            {props.children}
        </ProviderContext.Provider>
    );
}

export default Provider;

export function useProvider<T>(provider: ProviderToken<T>): T {
    const providers = useContext(ProviderContext);
    return providers[provider.tag];
}