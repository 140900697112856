import { useQuery } from 'react-query';
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryResult } from '../../../../shared/types/query';
import { useBrandAuthConfig } from '../../configuration/components/BrandAuthConfigProvider';
import { AuthProviderConfig } from '../types/auth-provider-config';

export function useGetAuthProviderConfig(): QueryResult<AuthProviderConfig> {
    const http = useHttpClient();
    const brandAuthConfig = useBrandAuthConfig();

    return useQuery({
        queryKey: ['discovery/authProviderConfig', http, brandAuthConfig],
        queryFn: () => {
            return http.request<AuthProviderConfig>({
                method: 'get',
                url: '/.discovery/.authorizationInfo',
                params: {
                    brandId: brandAuthConfig.bid,
                    preferredRedirectUri: brandAuthConfig.clb
                }
            }).then(result => result.data)
        }
    })
}
