import { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { useOidcUserManager } from './OidcUserManagerProvider';
import { useOidcUser } from './OidcUserProvider';


interface Props {}
function UserAuth(props: PropsWithChildren<Props>) {
    const userManager = useOidcUserManager()
    const oidcUser = useOidcUser();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(false);
        userManager.clearStaleState();
        userManager!.getUser().then(res => {
            console.log('USER MANAGER GET USER RESPONSE: ', res)
            if(res) {
                oidcUser.next(res);
                setIsLoaded(true);
            }
            if (!res) {
                userManager!.signinRedirect()
                    .then(res => console.log('SIGNIN RES: ', res))
                    .catch(err => console.log('SIGNIN ERR: ', err))

            }
        });

    }, [userManager, oidcUser])

    return (
        <Fragment>
            {isLoaded && props.children}
        </Fragment>
    )
}

export default UserAuth;