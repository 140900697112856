import { AxiosError } from "axios";
import { isNullOrUndefined } from "../../../shared/validators/common";

export function isAxiosError(error: any): error is AxiosError {
    const _error = error as AxiosError;
    return !isNullOrUndefined(_error) && 
        _error.isAxiosError && 
        _error.config !== undefined &&
        _error.message !== undefined &&
        _error.toJSON !== undefined
}