import { PropsWithChildren } from "react";
import styles from './ListView.module.css';

interface Props {}

function ListView(props: PropsWithChildren<Props>) {
    return (
        <div className={styles.list_view}>
            {props.children}
        </div>
    )
}

export default ListView;