import { ReactElement } from "react";
import { MutationResult } from "../../../../shared/types/query";
import MutationErrorViewer from "../../../error/components/error-viewer/MutationErrorViewer";
import Loader from "../loader/Loader";

interface Props<T> {
    result: MutationResult<any, T, any>;
    children?: (result: { isIdle: boolean, isSuccess: boolean, data?: T }) => ReactElement;
}

function MutationResultHandler<T>(props: Props<T>) {
    const { result, children } = props;
    const { isIdle, isLoading, isError, isSuccess, data, error, reset } = result;
    
    if(isLoading) return <Loader />
    if(isError && error) return <MutationErrorViewer error={error} reset={reset} />
    if((isIdle || isSuccess) && children) return children({ isIdle, isSuccess, data });
    return null;
}

export default MutationResultHandler