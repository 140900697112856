import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryOptions, QueryResult } from "../../../../shared/types/query";
import { Navigator } from "../../../common/types/navigator";
import { parserNavigator } from "../../../common/utils/parserNavigator";
import { UserRole, userRoleParser } from "../types/user-role";

export function useGetAllUserRoles(options?: QueryOptions<Navigator<UserRole>>): QueryResult<Navigator<UserRole>> {
    const http = useHttpClient();

    return useQuery({
        ...options,
        queryKey: ['userRoles/getAll', http],
        queryFn: () => {
            return http.request({
                method: 'GET',
                url: 'UserRoles'
            }).then(response => parserNavigator.fromJson(response.data, userRoleParser.fromJson))
        }
    })
}