import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOidcUserManager } from './OidcUserManagerProvider';
import { useOidcUser } from './OidcUserProvider';

interface Props { }

function AuthCallback(props: Props) {
    const userManager = useOidcUserManager();
    const oidcUser = useOidcUser();
    const navigate = useNavigate();


    useEffect(() => {
        if (userManager) {
            userManager.signinCallback()
                .then(user => {
                    if(user) {
                        oidcUser.next(user);
                        navigate('/pages/home')
                    }
                })
                .catch(err => console.log("ERROR: ", err))

        }
    }, [userManager, oidcUser, navigate])

    return <Fragment></Fragment>
}

export default AuthCallback