import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './PageContentContainer.module.css';

interface Props {
    listSectionContent: ReactNode;
    formSectionContent: ReactNode;
}

function PageContentContainer(props: Props) {
    return (
        <div className={classNames(styles.root)}>
            <div className={classNames(styles.content_card, styles.left_section)}>
                {props.listSectionContent}
            </div>
            <div className={classNames(styles.content_card, styles.right_section)}>
                {props.formSectionContent}
            </div>
        </div>
    )
}

export default PageContentContainer;