import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";
import { Queue } from "../../queues/types/queue";

export function useDeleteAssignedQueues(options?: MutationOptions<[string, Array<Queue>], AxiosResponse<any>>): MutationResult<[string, Array<Queue>], AxiosResponse<any>> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['users/deleteAssignedQueues', http],
        mutationFn: ([userId, queues]: [string, Array<Queue>]) => {
            const queueIds = queues.map(item => item.id).join(",");
            return http.request({
                method: 'DELETE',
                url: `Users/${userId}/assignedqueues/${queueIds}`,
            })
        }
    })
}