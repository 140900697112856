import {
  BaseButton,
  ContextualMenuItemType,
  IconButton,
  Persona,
  PersonaSize,
} from "@fluentui/react";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import SettingsPanel, { SettingsPanelRef } from "../setting-panel/SettingsPanel";
import ConnecsyLogo from "../logo/Logo";
import styles from "./Appbar.module.css";
import { useLogout } from "../../../core/auth/api/useLogout";
import { useSession } from "../../../core/session/SessionProvider";
import { useRxStateValue } from "../../../../shared/rxstate";

interface Props {
  onClickMenuButton: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | HTMLSpanElement>;
}

function Appbar(props: Props) {
  const { onClickMenuButton } = props;
  const { t } = useTranslation();

  const _toggleMenu = useCallback((e) => {
    onClickMenuButton && onClickMenuButton(e);
  }, [onClickMenuButton]);

  const settingsPanelref = useRef<SettingsPanelRef>(null);

  const { logout } = useLogout();

  const _onUserMenuItemClick = useCallback((event, item) => {
    switch (item.key) {
      case 'settings':
        settingsPanelref.current?.openPanel();
        break;
      case 'signout':
        logout();
        break;
      default:
        break;
    }
  }, [logout]);

  const session = useRxStateValue(useSession());

  return (
    <div className={styles.appbar_container}>
      <IconButton
        onClick={_toggleMenu}
        className={styles.toggle_menu_button}
        iconProps={{ iconName: "CollapseMenu" }}
        title="CollapseMenu"
        ariaLabel="CollapseMenu"
      />

      <div className={styles.logo_spacer}>

        <ConnecsyLogo
          width="48px"
          height="auto"
        />
      </div>

      <IconButton
        styles={{
          menuIcon: {
            display: "none",
          },
          root: {
            backgroundColor: "transparent",
          },
          rootChecked: {
            backgroundColor: "transparent",
          },
          rootHovered: {
            backgroundColor: "transparent",
          },
          rootExpanded: {
            backgroundColor: "transparent",
          },
          rootFocused: {
            backgroundColor: "transparent",
          },
          rootPressed: {
            backgroundColor: "transparent",
          },
        }}
        menuProps={{
          onItemClick: _onUserMenuItemClick,
          items: [
            {
              key: "user",
              text: session?.fullName ?? '',
              iconProps: { iconName: "Contact" },
              itemType: ContextualMenuItemType.Normal,
            },
            {
              key: "divider1",
              text: "-",
              itemType: ContextualMenuItemType.Divider,
            },
            {
              key: "settings",
              text: t("settings"),
              iconProps: { iconName: "Settings" },
            },
            // {
            //   key: "about",
            //   text: t("about"),
            //   iconProps: { iconName: "Info" },
            // },
            // {
            //   key: "help",
            //   text: t("help"),
            //   iconProps: { iconName: "Help" },
            // },
            // {
            //   key: "divider2",
            //   text: "-",
            //   itemType: ContextualMenuItemType.Divider,
            // },
            {
              key: "signout",
              text: t("signout"),
              iconProps: { iconName: "SignOut" },
            },
          ],
        }}
        persistMenu={true}
      >
        <Persona
          imageUrl={session?.profileImage?.imageUri}
          imageInitials={session?.fullName?.charAt(0) ?? ''}
          size={PersonaSize.size40}
          imageAlt={session?.fullName ?? ''}
          styles={{ root: { width: 40 } }}
        />
      </IconButton>


      <SettingsPanel ref={settingsPanelref} />
    </div>
  );
}

export default Appbar;
