import { useCallback } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { VoidCallback } from "../../../../shared/types/common"
import { isNullOrUndefined } from "../../../../shared/validators/common";
import { useBrand } from "../../configuration/components/BrandProvider";
import { useAuthProviderConfig } from "../../discovery/AuthProviderConfigProvider";
import { useDeleteSession } from "../../session/api/useDeleteSession";
import { useDeleteSubscription } from "../../subscription/api";
import { useSubscriptionInstance } from "../../subscription/SubscriptionProvider";
import { useOidcUserManager } from "../components/OidcUserManagerProvider";

export interface UseLogoutReturn {
    logout: VoidCallback;
    forceLogout: VoidCallback;
}

export const useLogout = (): UseLogoutReturn => {
    const oidcUserManager = useOidcUserManager();
    const brand = useBrand();
    const navigate = useNavigate();
    const authProviderConfig = useAuthProviderConfig();

    const { mutate: clearUserData } = useMutation({
        mutationKey: ['user/logout'],
        mutationFn: () => {
            return Promise.all([
                oidcUserManager.removeUser(),
                oidcUserManager.revokeTokens(['access_token', 'refresh_token']),
                oidcUserManager.stopSilentRenew(),
                oidcUserManager.clearStaleState(),
            ])
        },
        onSuccess: (data) => {
            const brandId = brand.brand.value;
            if(isNullOrUndefined(authProviderConfig?.endSessionPath)) {
                navigate(`/signin/${brandId}`);
            } else {
                oidcUserManager.signoutRedirect();
            }
            brand.removeBrand()
        }
    })


    const { mutate: deleteSession } = useDeleteSession({
        onSuccess: () => {
            clearUserData();
        }
    });

    const subscriptionInstance = useSubscriptionInstance();
    const { mutate: deleteSubscription } = useDeleteSubscription({
        onSuccess: () => {
            deleteSession();
        }
    })

    const forceLogout = useCallback(() => {
        clearUserData();
    }, [clearUserData])

    const logout = useCallback(() => {
        deleteSubscription(subscriptionInstance.id);
        sessionStorage.clear();
    }, [deleteSubscription, subscriptionInstance])

    return {
        logout,
        forceLogout
    }
}