import { AxiosError } from "axios";
import { CommonError } from "../types/common-error";
import { isAxiosError } from "./isAxiosError";
import { isCommonError } from "./isCommonError";
import { isError } from "./isError";

const _fromAxiosError = (err: AxiosError) => {
    const json = err.toJSON() as Record<string, any>;

    const parsedError = {
        message: json.message,
        details: {
            description: err.response?.data.description,
            error_code: err.response?.data.errorCode,
            status: json.status,
            request_method: json.config.method,
            request_base_url: json.config.baseURL,
            request_url: json.config.url,
        }
    };

    return parsedError;
}

function _fromError(error: Error): CommonError {
    return {
        message: error.message,
        details: {}
    }
}

const _parseError = (err: any) => {
    if (isCommonError(err)) return err;
    if (isAxiosError(err)) return _fromAxiosError(err);
    if(isError(err)) return _fromError(err);
    return null;
}

interface CommonErrorParser {
    parseError(error: any): CommonError | null;
    fromAxiosError(error: AxiosError): CommonError;
    fromError(error: Error): CommonError;
}

export const parserCommonError: CommonErrorParser = {
    parseError: _parseError,
    fromAxiosError: _fromAxiosError,
    fromError: _fromError
}