import dayjs from "dayjs";
import { FromJsonParser } from "../../../../shared/types/parser";
import { CommunicationChannel } from "./communication-channel";
import { ProfileImage, profileImageParser } from "./profile-image";
import { UserRole, userRoleParser } from "./user-role";

export interface User {
    id: string;
    userName: string;
    fullName: string;
    preferredExtension: string;
    email: string;
    externalId: string;
    profileImage: ProfileImage | null;
    isDeleted: boolean;
    isLoggedOn: boolean;
    loggedOnExtension: boolean;
    directCallPriorityLevel: number;
    isEnabled: boolean;
    isLockedOut: boolean;
    created: Date | null;
    lastLogon: Date | null;
    userRole: UserRole | null;
    recordOutgoingCalls: boolean;
    allowTemporaryQueueAssign: boolean;
    canSetAvailability: boolean;
    communicationChannelVoice: boolean;
    communicationChannelChat: boolean;
    configurationProfileId: number;
};

export interface UserParser extends FromJsonParser<User> { }

export const userParser: UserParser = {
    fromJson: (json) => {
        return {
            id: json['id'] ?? '',
            userName: json['userName'] ?? '',
            fullName: json['fullName'] ?? '',
            preferredExtension: json['preferredExtension'] ?? '',
            email: json['email'] ?? '',
            externalId: json['externalId'] ?? '',
            profileImage: json['profileImage'] ? profileImageParser.fromJson(json['profileImage']) : null,
            isDeleted: json['isDeleted'] ?? false,
            isLoggedOn: json['isLoggedOn'] ?? false,
            loggedOnExtension: json['loggedOnExtension'] ?? false,
            directCallPriorityLevel: json['directCallPriorityLevel'] ?? 0,
            isEnabled: json['isEnabled'] ?? false,
            isLockedOut: json['isLockedOut'] ?? false,
            created: json['created'] ? dayjs(json['created']).toDate() : null,
            lastLogon: json['lastLogon'] ? dayjs(json['lastLogon']).toDate() : null,
            userRole: json['userRole'] ? userRoleParser.fromJson(json['userRole']) : null,
            recordOutgoingCalls: json['recordOutgoingCalls'] ?? false,
            allowTemporaryQueueAssign: json['allowTemporaryQueueAssign'] ?? false,
            canSetAvailability: json['canSetAvailability'] ?? false,
            communicationChannelVoice: json['channelAvailability']?.indexOf(CommunicationChannel.Voice) !== -1,
            communicationChannelChat: json['channelAvailability']?.indexOf(CommunicationChannel.Chat) !== -1,
            configurationProfileId: json['configurationProfileId'] ?? null,
        }
    }
}