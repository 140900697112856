export const isNullOrUndefined = (value: any): boolean => {
    return value === null || value === undefined;
}

export const isString = (value: any): value is string => {
    return typeof value === 'string'
}

export const isNotEmptyString = (value: string | null | undefined): boolean => {
    return !isNullOrUndefined(value) && isString(value) && value.trim().length !== 0;
}

export const isEmptyString = (value: string | null | undefined): boolean => {
    return !isNotEmptyString(value);
}

export const isLetterString = (value: string): boolean => {
    const _letter = /^[a-zA-ZüğöəıiçşÜĞÖƏIİÇŞ]+$/;
    return _letter.test(value);
};

export const isNumber = (value: any): boolean => {
    const _number = /^\d+$/;
    return _number.test(value);
};

export const isEmail = (value: string): boolean => {
    const _email = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
    return _email.test(value);
};


export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const urlRegexp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
