import { ReactNode } from 'react';
import Loader from '../../common/components/loader/Loader';
import MutationErrorViewer from '../../error/components/error-viewer/MutationErrorViewer';
import UnauthorizedUserError from '../../error/components/unauthorized-user-error/UnauthorizedUserError';
import { ErrorCodes } from '../../error/constants/error-codes';
import { useLogout } from '../auth/api/useLogout';
import sessionsApi from './api';
import SessionProvider from './SessionProvider';

interface Props {
  children?: ReactNode;
}

function SessionManager(props: Props) {
  const {forceLogout} = useLogout();

  const getAllSessionsResult = sessionsApi.useGetAllSessions({
    onSuccess: (result) => {
      if (!result.items || !result.items.length) {
        createNewSessionResult.mutate(undefined);
      }
    }
  });

  const createNewSessionResult = sessionsApi.usePostNewSession();

  if (createNewSessionResult.isSuccess && createNewSessionResult.data) {
    const session = createNewSessionResult.data?.data;
    return <SessionProvider session={session}> {props.children} </SessionProvider>
  }

  if (getAllSessionsResult.isError) {
    if (getAllSessionsResult.error?.response?.data?.errorCode === ErrorCodes.UNAUTHORIZED_USER) {
      return <UnauthorizedUserError/>
    }
    return <MutationErrorViewer error={getAllSessionsResult.error} reset={forceLogout}/>;
  }

  if (createNewSessionResult.isError) return <MutationErrorViewer error={createNewSessionResult.error}
                                                                  reset={forceLogout}/>

  if (getAllSessionsResult.data?.items && getAllSessionsResult.data.items.length > 0) {
    const session = getAllSessionsResult.data.items[0];
    return <SessionProvider session={session}> {props.children} </SessionProvider>
  }

  return <Loader/>
}

export default SessionManager;