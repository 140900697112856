import { User } from "oidc-client-ts";
import React, { ReactNode, useEffect } from "react";
import { useOidcUserManager } from "../auth/components/OidcUserManagerProvider";
import sessionsApi from "./api";
import { useSession } from "./SessionProvider";

interface Props {
    children?: ReactNode;
}

function SessionUpdater(props: Props) {
    const session = useSession();
    const oidcUserManager = useOidcUserManager();

    sessionsApi.useRenewSession({
        onSuccess: (result) => session.next(result.data),
        onError: (err) => {
            console.error('Error: ', err);
        },
    });

    const { mutate: reauthenticateSession } = sessionsApi.useReauthenticatedSession();

    useEffect(() => {
        const _userLoaded = (user: User) => {
            reauthenticateSession();
        }

        oidcUserManager.events.addUserLoaded(_userLoaded);
        return () => {
            oidcUserManager.events.removeUserLoaded(_userLoaded);

        }

    }, [oidcUserManager, reauthenticateSession])

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export default SessionUpdater;