import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";

export function useDeleteCalendar(options?: MutationOptions<string, AxiosResponse>): MutationResult<string, AxiosResponse> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['calendars/delete', http],
        mutationFn: (calendarId: string) => {
            return http.request({
                method: 'delete',
                url: `QueueCalendars/${calendarId}`,
            })
        },
    })
}