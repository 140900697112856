import { useQuery } from 'react-query';
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryOptions, QueryResult } from "../../../../shared/types/query";


export function useClientInitialized(options?: QueryOptions<any>): QueryResult<any> {
    const http = useHttpClient();

    return useQuery<any>({
        ...options,
        queryKey: ['client/clientInitialized', http],
        queryFn: () => {
            return http.request({
                method: 'post',
                url: 'Client/clientinitialized',
            })
        },
    })
}