import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryOptions, QueryResult } from "../../../../shared/types/query";

export function useRenewSession(options?: QueryOptions<AxiosResponse<any>>): QueryResult<AxiosResponse<any>> {
    const http = useHttpClient();

    return useQuery({
        ...options,
        queryKey: ['sessions/renew', http],
        queryFn: () => {
            return http.request({
                method: 'post',
                url: `/renew`,
            })
        },
        refetchIntervalInBackground: true,
        refetchInterval: (result) => {
            if(!result?.data.sessionTTL) return false;
            const ms = (result.data.sessionTTL - 20) * 1000;
            return ms;
        }
    })
}