import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";
import { User, userParser } from "../types/user";
import { UserFormData, userFormDataParser } from "../types/user-form-data";

export function useCreateUser(options?: MutationOptions<UserFormData, User>): MutationResult<UserFormData, User> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['users/create', http],
        mutationFn: (formData: UserFormData) => {
            const data = userFormDataParser.toRequestData(formData);
            return http.request({
                method: 'POST',
                url: 'Users',
                data: data,
            }).then(response => userParser.fromJson(response.data))
        }
    })
}