import { FromJsonParser } from "../../../../shared/types/parser"

export interface ProfileImage {
    imageUri: string,
    originalUri: string,
    viewPort: {
        offsetX: number,
        offsetY: number,
        width: number,
        height: number
    } | null
}


export interface ProfileImageParser extends FromJsonParser<ProfileImage> {}

export const profileImageParser: ProfileImageParser = {
    fromJson: (json) => {
        return {
            imageUri: json['imageUri'] ?? '',
            originalUri: json['originalUri'] ?? '',
            viewPort: json['viewPort'] ?? null,
        }
    }
}