import { Fragment, PropsWithChildren } from "react";
import Provider, { createProviderToken, useProvider } from "../../../../shared/provider";
import Loader from "../../../common/components/loader/Loader";
import QueryErrorViewer from "../../../error/components/error-viewer/QueryErrorViewer";
import { useConfigApi } from "../api";
import { ServerConfiguration } from "../types/server-configuration";

const providerToken = createProviderToken<ServerConfiguration>('serverConfiguration');

export function useServerConfiguration(): ServerConfiguration {
    return useProvider(providerToken);
}

interface Props { }

function ServerConfigurationProvider(props: PropsWithChildren<Props>) {
    const configurationApi = useConfigApi();

    const { isLoading, isError, isSuccess, error, data } = configurationApi.useGetServerConfiguration();

    return (
        <Fragment>
            {isLoading && <Loader />}
            {isError && <QueryErrorViewer error={error} />}
            {isSuccess && data && (
                <Provider provide={providerToken} value={data}>
                    {props.children}
                </Provider>
            )}
        </Fragment>
    )
}

export default ServerConfigurationProvider
