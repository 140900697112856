import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryOptions, QueryResult } from "../../../../shared/types/query";
import { isNotEmptyString } from "../../../../shared/validators/common";
import { Navigator } from "../../../common/types/navigator";
import { parserNavigator } from "../../../common/utils/parserNavigator";
import { Queue } from "../../queues/types/queue";
import { parserQueue } from "../../queues/utils/parserQueue";

export function useGetAssignedQueues(userId?: string, options?: QueryOptions<Navigator<Queue>>): QueryResult<Navigator<Queue>> {
    const http = useHttpClient();

    return useQuery({
        ...options,
        queryKey: ['users/getAssignedQueues', userId, http],
        queryFn: () => {
            return http.request({
                method: 'GET',
                url: `Users/${userId}/assignedqueues`,
            }).then(response => parserNavigator.fromJson(response.data, parserQueue.fromJson))
        },
        enabled: isNotEmptyString(userId),
    })
}