import { ReactElement, useMemo } from "react";
import { Permission } from "../../modules/core/auth/types/permission";
import { useUserData } from "../../modules/core/auth/components/UserDataProvider";

interface Props {
    permissionChecker: (permission: Permission) => boolean;
    successElement: ReactElement;
    failureElement: ReactElement;
}

function PermissionCheckerGuard(props: Props) {
    const { permissionChecker } = props;
    const { permission } = useUserData();

    const checkResult = useMemo(() => {
        return permissionChecker(permission);
    }, [permission, permissionChecker])


    if (checkResult) return props.successElement

    return props.failureElement;
}

export default PermissionCheckerGuard;