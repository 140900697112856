import { User } from "oidc-client-ts";
import { ReactNode, useEffect } from "react"
import Provider, { createProviderToken, useProvider } from "../../../../shared/provider";
import { RxState, useRxState } from "../../../../shared/rxstate";
import { useOidcUserManager } from "./OidcUserManagerProvider";

const oidcUserProviderToken = createProviderToken<RxState<User | null>>('oidcUser');

export function useOidcUser(): RxState<User | null> {
    return useProvider(oidcUserProviderToken);
}

interface Props {
    children?: ReactNode;
}

function OidcUserProvider(props: Props) {
    const oidcUserManager = useOidcUserManager();
    const oidcUser = useRxState<User | null>(null);

    useEffect(() => {
        const _userLoaded = (user: User) => {
            console.log('USER LOADED EVENT: ', user);
            oidcUser.next(user);
        }

        const _userUnloaded = () => {
            console.log('USER UNLOADED EVENT');
        }

        const _userSignedIn = () => {
            console.log('USER SIGNED IN EVENT');
        }

        const _userSignetOut = () => {
            console.log('USER SIGNED OUT EVENT');
        }

        oidcUserManager.events.addUserLoaded(_userLoaded);
        oidcUserManager.events.addUserUnloaded(_userUnloaded);
        oidcUserManager.events.addUserSignedIn(_userSignedIn);
        oidcUserManager.events.addUserSignedOut(_userSignetOut);

        return () => {
            oidcUserManager.events.removeUserLoaded(_userLoaded);
            oidcUserManager.events.removeUserUnloaded(_userUnloaded);
            oidcUserManager.events.removeUserSignedIn(_userSignedIn);
            oidcUserManager.events.removeUserSignedOut(_userSignetOut)
        }

    }, [oidcUserManager, oidcUser])

    return (
        <Provider provide={oidcUserProviderToken} value={oidcUser}>
            {props.children}
        </Provider>
    )
}

export default OidcUserProvider