import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";
import { CustomField } from "../types/custom-field";

export function useDeleteCustomField(options?: MutationOptions<[string, CustomField], AxiosResponse<any>>): MutationResult<[string, CustomField], AxiosResponse<any>> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['users/deleteCustomFields', http],
        mutationFn: ([userId, customField]: [string, CustomField]) => {  
            return http.request({
                method: 'DELETE',
                url: `Users/${userId}/customfields/${customField.customFieldKey}`,
            })
        }
    })
}