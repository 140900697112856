import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { UpdateDataCallback, UpdateDataCallbackAsync, VoidCallback } from "../types/common";

export interface HttpClient {
    request<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>>;
    addRequestInterceptor(onFulfilled?: UpdateDataCallbackAsync<AxiosRequestConfig>, onRejected?: UpdateDataCallback<any>): VoidCallback;
    addResponseInterceptor(onFulfilled?: UpdateDataCallbackAsync<AxiosResponse>, onRejected?: UpdateDataCallback<any>): VoidCallback;
    clone(): HttpClient;
}

export function createHttpClient(config?: AxiosRequestConfig): HttpClient {
    const _instance: AxiosInstance = axios.create(config);
    const _config = config;

    const request = <T>(config: AxiosRequestConfig<any>): Promise<AxiosResponse<T, any>> => {
        return _instance.request(config);
    }

    const addRequestInterceptor = (onFulfilled?: UpdateDataCallbackAsync<AxiosRequestConfig<any>>, onRejected?: UpdateDataCallback<any>): VoidCallback => {
        const id = _instance.interceptors.request.use(onFulfilled, onRejected);
        return () => {
            _instance.interceptors.request.eject(id);
        }
    }

    const addResponseInterceptor = (onFulfilled?: UpdateDataCallbackAsync<AxiosResponse<any, any>>, onRejected?: UpdateDataCallback<any>): VoidCallback => {
        const id = _instance.interceptors.response.use(onFulfilled, onRejected);
        return () => {
            _instance.interceptors.response.eject(id);
        }
    }

    const clone = (config?: AxiosRequestConfig): HttpClient => {
        return createHttpClient(Object.assign(_config || {}, config));
    }

    return {
        request,
        addRequestInterceptor,
        addResponseInterceptor,
        clone
    }
}