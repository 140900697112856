import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import BrandCheckerGuard from "../../../routes/guards/BrandCheckerGuard";
import DiscoveryBaseUrlWithBasicAuthHeaderInterceptor from "../http/components/DiscoveryBaseUrlWithBasicAuthHeaderInterceptor";
import OidcUserManagerProvider from "../auth/components/OidcUserManagerProvider";
import OidcUserProvider from "../auth/components/OidcUserProvider";
import AuthProviderConfigProvider from "../discovery/AuthProviderConfigProvider";
import BrandAuthConfigProvider from "../configuration/components/BrandAuthConfigProvider";

interface Props {
    children?: ReactNode;
}

function AppBootstrap(props: Props) {
  return (
    <BrandCheckerGuard
      failureElement={<Navigate to="/signin" replace/>}
      successElement={
        <BrandAuthConfigProvider>
          <DiscoveryBaseUrlWithBasicAuthHeaderInterceptor>
            <AuthProviderConfigProvider>
              <OidcUserManagerProvider>
                <OidcUserProvider>
                  {props.children}
                </OidcUserProvider>
              </OidcUserManagerProvider>
            </AuthProviderConfigProvider>
          </DiscoveryBaseUrlWithBasicAuthHeaderInterceptor>
        </BrandAuthConfigProvider>
      }
    />
  )
}

export default AppBootstrap;