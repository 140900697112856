import classNames from "classnames";
import React from "react";

import { useServerConfiguration } from 'modules/core/configuration/components/ServerConfigurationProvider';
import styles from "./Logo.module.css";

interface Props {
  width: string;
  height: string;
  brandName?: string;
}

function ConnecsyLogo(props: Props) {
  const { brandName } = props;
  const serverConfiguration = useServerConfiguration();

  return (
    <div className={classNames(styles.logo_container)}>
      <img
        src={"/assets/images/logo.svg"}
        alt={"ConnecsyCloud"}
        width={props.width}
        height={props.height}
        style={{
          width: props.width,
          height: props.height,
        }}
      />

      <div>
        <span className={classNames(styles.app_name_text)}>
          {`Connecsy | Cloud ${serverConfiguration?.enableAgents ? 'Agent' : ''}`}
        </span>
          {brandName && <span className={styles.brand_text}>For {brandName}</span>}
      </div>
    </div>
  );
}

export default ConnecsyLogo;
