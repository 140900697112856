import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

interface Props {
    size?: SpinnerSize;
}

function Loader(props: Props) {
    return (
        <Spinner size={props.size || SpinnerSize.large} />
    )
}

export default Loader;