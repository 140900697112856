import { Dropdown, IDropdownOption, Panel } from '@fluentui/react';
import React, { ForwardRefRenderFunction, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface SettingsPanelRef {
    openPanel(): void;
    closePanel(): void;
}

interface Props { }

const SettingsPanel: ForwardRefRenderFunction<SettingsPanelRef, Props> = (props, ref) => {
    const [isOpenPanel, setIsOpenPanel] = useState<boolean>(false);

    const _openPanel = useCallback(() => { setIsOpenPanel(true) }, []);
    const _closePanel = useCallback(() => { setIsOpenPanel(false) }, [])

    useImperativeHandle(ref, () => {
        return {
            openPanel: _openPanel,
            closePanel: _closePanel,
        }
    }, [_openPanel, _closePanel])

    const [t, i18next] = useTranslation();

    const changeLanguage = useCallback((event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        if (option) {
            i18next.changeLanguage(option.key as string);
        }
    }, [i18next])

    const languages = useMemo<Array<IDropdownOption>>(() => {
        return [
            { key: 'en', text: 'English' },
            { key: 'fr', text: 'Français' },
            { key: 'de', text: 'Deutsch' },
        ]
    }, [])

    return (
        <Panel
            headerText={t("common:settings")}
            isOpen={isOpenPanel}
            onDismiss={_closePanel}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel={t("common:close")}
        >
            <Dropdown
                label={t("common:language")}
                selectedKey={i18next.language}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={changeLanguage}
                placeholder="Select an option"
                options={languages}
                styles={{ root: { width: '100%' } }}
            />
        </Panel>
    )
}


export default React.forwardRef(SettingsPanel);