import { useTranslation } from "react-i18next"

interface Props {}

function AccessDenied(props: Props) {
    const [t] = useTranslation();

    return (
        <h4 style={{ textAlign: 'center' }}>{t("common:accessDenied")}</h4>
    )
}

export default AccessDenied