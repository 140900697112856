export interface Storage {
    saveBrand(brandId: string): void;
    getBrand(): string | null;
    removeBrand(): void;
}


const _usedStorage = sessionStorage;
const KEY_BRAND = 'brandId';
const _saveBrand = (brandId: string) => _usedStorage.setItem(KEY_BRAND, `${brandId}`);
const _getBrand = () => {
    const _brandId = _usedStorage.getItem(KEY_BRAND)
    if(_brandId) return _brandId;
    return null;
}
const _removeBrand = () => _usedStorage.removeItem(KEY_BRAND);

const _createStorage = ():Storage => {
    return {
        saveBrand: _saveBrand,
        getBrand: _getBrand,
        removeBrand: _removeBrand
    }
}


let _storage: Storage;
export const useStorage = (): Storage => {
    if(_storage === undefined)
        _storage = _createStorage();
    
    return _storage;
}
