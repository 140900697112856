import { Text } from '@fluentui/react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Page.module.css';

interface Props {
    title: string;
    child: ReactElement;
}

function Page(props: Props) {
    const { t } = useTranslation();


    return (
        <div className={styles.root}>
            <div className={styles.title_container}>
                <Text variant='xLarge'>
                    {t(props.title)}
                </Text>
            </div>

            <div className={styles.content}>
                {props.child}
            </div>
        </div>
    )
}

export default Page;


