import { FieldMetaProps } from "formik";
import { getFieldMetaError } from "../../utils/getFieldMetaError";
import ErrorMessage from "../error-message/ErrorMessage";

interface Props {
    meta: FieldMetaProps<any>
}

function FieldMetaError(props: Props) {
    const error = getFieldMetaError(props.meta);
    return <ErrorMessage message={error} />
}

export default FieldMetaError;