import { useServerConfiguration } from 'modules/core/configuration/components/ServerConfigurationProvider';

export interface IMapItem {
  key: string;
  text: string;
}

export interface MappedCustomFieldsOptions {
  currentList: Array<IMapItem>;
  deleteIds: Array<string | number>;
}

const CUSTOM_FIELD_SUBSTR_START = 'CustomData';

export const isCustomFiled = (item: string) => item.includes(CUSTOM_FIELD_SUBSTR_START);

export const useMappedCustomFields = (options: MappedCustomFieldsOptions) => {
  const serverConfig = useServerConfiguration();
  const { customDataFields } = serverConfig.sharedClientConfiguration;
  const { currentList, deleteIds } = options;

  const listRemover = (item: any) => {
    if (deleteIds && deleteIds.includes(item?.key)) {
      return false;
    }

    return item;
  };

  const listEditor = (item: any) => {
    if (isCustomFiled(item.key)) {
      const [, customFieldNum] = item.key.split(CUSTOM_FIELD_SUBSTR_START);

      if (!customDataFields[customFieldNum]) {
        return null;
      }

      return {
        key: item.key,
        text: customDataFields[customFieldNum],
      }
    }

    return item;
  };


  return {
    mappedList: currentList
      ?.map(listEditor)
      ?.filter(listRemover),
  }
};