export interface Permission {
    queues: {
        view: boolean;
    };
    skills: {
        view: boolean;
    };
    nrc: {
        view: boolean;
    };
    chatSettings: {
        view: boolean;
    };
    emailTemplates: {
        view: boolean;
    }
    taskScheduler: {
        view: boolean;
    };
    calendars: {
        view: boolean;
    };
    reports: {
        view: boolean;
    };
    contacts: {
        view: boolean;
    }
    users: {
        view: boolean;
        fields: {
            recordOutgoingCalls: boolean;
            allowTemporaryRemovalFromQueue: boolean;
            preferredExtension: boolean;
            directCallPriorityLevel: boolean;
        }
    };
    profiles: {
        view: boolean
    },
    recordings: {
      view: boolean;
    },
    cannedResponses: {
        view: boolean;
    }
}


export const selectPermissionSkillsView = (permission: Permission) => permission.skills.view;
export const selectPermissionQueuesView = (permission: Permission) => permission.queues.view;
export const selectPermissionChatSettingsView = (permission: Permission) => permission.chatSettings.view;
export const selectPermissionEmailTemplatesView = (permission: Permission) => permission.emailTemplates.view;
export const selectPermissionTaskSchedulerView = (permission: Permission) => permission.taskScheduler.view;
export const selectPermissionCalendarsView = (permission: Permission) => permission.calendars.view;
export const selectPermissionUsersView = (permission: Permission) => permission.users.view;
export const selectPermissionReportsView = (permission: Permission) => permission.reports.view;
export const selectPermissionContactsView = (permission: Permission) => permission.contacts.view;
export const selectPermissionCannedResponsesView = (permission: Permission) => permission.cannedResponses.view
  && permission.chatSettings.view;
export const selectPermissionProfilesView = (permission: Permission) => permission.profiles.view;
export const selectPermissionNRCView = (permission: Permission) => permission.nrc.view;
export const selectPermissionRecordingsView = (permission: Permission) => permission.recordings.view;
