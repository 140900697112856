import { FormikHelpers } from "formik";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import MutationResultHandler from "../../../../common/components/handlers/MutationResultHandler";
import { useCreateCalendar } from "../../api/useCreateCalendar";
import { Calendar } from "../../types/calendar";
import CalendarForm from "./CalendarForm";
import cardStyles from '../../../../../theme/styles/Card.module.css';


interface Props { }

function CreateCalendarForm(prosp: Props) {
    const queryClient = useQueryClient();

    const createCalendarResult = useCreateCalendar()
    const { isLoading, mutate } = createCalendarResult

    const submit = useCallback((data: Calendar, helpers: FormikHelpers<Calendar>) => {
        mutate(data, {
            onSuccess: () => {
                queryClient.invalidateQueries(['calendars/getAll']);
                helpers.resetForm();
            }
        })
    }, [mutate, queryClient])

    return (
        <div className={cardStyles.card}>
            <div className={cardStyles.card_content}>
                <CalendarForm
                    disabledActions={isLoading}
                    onSubmit={submit}
                />
                <MutationResultHandler
                    result={createCalendarResult}
                />
            </div>
        </div>
    )
}

export default CreateCalendarForm;