import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { Navigator } from "../../../common/types/navigator";
import { Session } from "../types/session";
import { QueryOptions, QueryResult } from '../../../../shared/types/query';

export function useGetAllSessions(options?: QueryOptions<Navigator<Session>>): QueryResult<Navigator<Session>, any> {
    const http = useHttpClient();

    return useQuery({
        ...options,
        queryKey: ['sessions/getAll', http],
        queryFn: () => {
            return http.request<Navigator<Session>>({
                method: 'get',
                url: '/?authenticatedSessionType=ManagementSession',
            }).then(result => result.data)
        },
    })
}