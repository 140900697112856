import { useTranslation } from 'react-i18next';
import { useGetAllCalendars } from '../../api/useGetAllCalendars';
import { Calendar } from '../../types/calendar';
import AddAction from '../../../../common/components/actions/add-action-button/AddAction';
import QueryResultHandler from '../../../../common/components/handlers/QueryResultHandler';
import { Fragment, useCallback } from 'react';
import ItemsNotFound from '../../../../error/components/items-not-found/ItemsNotFound';
import { useSearchParams } from 'react-router-dom';
import ListView from '../../../../common/components/list-view/ListView';
import ListViewItem from '../../../../common/components/list-view/ListViewItem';
import ListViewItemIcon from '../../../../common/components/list-view/ListViewItemIcon';
import cardStyles from '../../../../../theme/styles/Card.module.css';

interface Props {
    selectedCalendarId?: string | null;
}

function CalendarList(props: Props) {
    const { t } = useTranslation();
    const [, setSearchParams] = useSearchParams();

    const allCalendarsResult = useGetAllCalendars();

    const onClickCreateCalendar = useCallback(() => {
        setSearchParams('');
    }, [setSearchParams])

    const onClickCalendar = useCallback((calendar: Calendar) => {
        setSearchParams({ calendarId: calendar.id })
    }, [setSearchParams])

    return (
        <div className={cardStyles.card}>
            <div className={cardStyles.card_actions_container}>
                <AddAction iconName='Calendar' label={t("common:addCalendar")} onClick={onClickCreateCalendar} />
            </div>
            <div className={cardStyles.card_content}>
                <QueryResultHandler result={allCalendarsResult}>
                    {({ data }) => (
                        <Fragment>
                            {(!data.items || !data.items.length) && <ItemsNotFound />}
                            {data.items && (
                                <ListView>
                                    {data.items.map((item, index) => (
                                        <ListViewItem
                                            key={item.id}
                                            isSelected={props.selectedCalendarId === item.id}
                                            onClick={() => onClickCalendar(item)}
                                            icon={<ListViewItemIcon iconName="Calendar" />}
                                            primaryText={item.name}
                                        />
                                    ))}
                                </ListView>
                            )}
                        </Fragment>
                    )}
                </QueryResultHandler>
            </div>
        </div>
    )
}

export default CalendarList;