import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { useUserData } from "modules/core/auth/components/UserDataProvider";
import { selectPermissionCannedResponsesView } from 'modules/core/auth/types/permission';

import styles from './Menu.module.css';

interface Props {
  onClickMenuItem?: React.MouseEventHandler<HTMLAnchorElement>;
}

function Menu(props: Props) {
  const { onClickMenuItem } = props;
  const { t } = useTranslation();

  const _onClickMenu = useCallback((e) => {
    onClickMenuItem && onClickMenuItem(e);
  }, [onClickMenuItem])

  const { permission } = useUserData();

  const menus = useMemo(() => {
    const items = [];
    items.push({ key: "home", title: t("home"), url: "home" });
    if (permission.users.view) items.push({ key: "users", title: t("userManagement"), url: "users" },)
    if (permission.queues.view) items.push({ key: "queues", title: t("queues"), url: "queues" })
    if (permission.emailTemplates.view) items.push({ key: "email", title: t("emailTemplates"), url: "email" });
    if (permission.chatSettings.view) items.push({ key: "chat", title: t("chatSettings"), url: "chat" });
    if (permission.taskScheduler.view) items.push({ key: "tasks", title: t("taskScheduler"), url: "tasks" });
    if (permission.calendars.view) items.push({ key: "calendars", title: t("calendars"), url: "calendars" });
    if (permission.contacts.view) items.push({ key: "contacts", title: t("contacts"), url: "contacts" });
    if (permission.contacts.view) items.push({ key: "directoryManagement", title: t("directoryManagement"), url: "directory" });
    if (permission.reports.view) items.push({ key: "reports", title: t("reports"), url: "reports" });
    if (permission.profiles.view) items.push({ key: "profiles", title: t("profiles"), url: "profiles" });
    if (permission.skills.view) items.push({ key: "skills", title: t("skills"), url: "skills" });
    if (permission.nrc.view) items.push({ key: "nrc", title: t("nrc"), url: "nrc" });
    if (permission.recordings.view) items.push({ key: "recordings", title: t("recordings"), url: "recordings" });
    if (selectPermissionCannedResponsesView(permission)) {
      items.push({ key: "cannedResponses", title: t("cannedResponses"), url: "canned-responses" });
    }

    return items;
  }, [t, permission])

  return (
    <ul>
      {menus.map((item) => (
        <li key={item.key}>
          <NavLink
            onClick={_onClickMenu}
            to={item.url}
            className={({ isActive }) => classNames(styles.menu_item, { [styles.active_menu_item]: isActive })}
          >
            {item.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

export default Menu;
