import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";

export function useReauthenticatedSession(options?: MutationOptions<void, AxiosResponse<any>>): MutationResult<void, AxiosResponse<any>> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['sessions/reauthenticated', http],
        mutationFn: () => {
            return http.request({
                method: 'post',
                url: `/reauthenticated`,
            })
        }
    })
}