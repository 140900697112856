import { ReactNode, useMemo } from "react";
import HttpClientProvider, { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { useOidcUser } from "../../auth/components/OidcUserProvider";
import { useBrandAuthConfig } from "../../configuration/components/BrandAuthConfigProvider";
import { useApiServerInfo } from "../../discovery/ApiServerInfoProvider";
import { getBearerAuthHeader,  getRestApiBaseUrl } from "../utils/utils";

interface Props {
    children?: ReactNode;
}

function AuthInterceptor(props: Props) {
    const brandAuthConfig = useBrandAuthConfig();
    const apiServerInfo = useApiServerInfo();
    const oidcUser = useOidcUser();
    const http = useHttpClient();

    const _httpClient = useMemo(() => {
        const _http = http.clone();

        _http.addRequestInterceptor(config => {
            config.baseURL = getRestApiBaseUrl(brandAuthConfig, apiServerInfo);

            const headers = config.headers || {};
            headers['Authorization'] = getBearerAuthHeader(oidcUser.value!)
            config.headers = headers;

            return config;
        });

        return _http;
    }, [http, brandAuthConfig, oidcUser, apiServerInfo])

    return (
        <HttpClientProvider httpClient={_httpClient}>
            {props.children}
        </HttpClientProvider>
    )
}

export default AuthInterceptor;