import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";
import { ClientSettings } from "../types/client-settings";

export function useSetClientSettings(options?: MutationOptions<[string, ClientSettings], AxiosResponse<any>>): MutationResult<[string, ClientSettings], AxiosResponse<any>> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['users/setClientSettings', http],
        mutationFn: ([userId, clientSettings]: [string, ClientSettings]) => {
            return http.request({
                method: 'POST',
                url: `Users/${userId}/settings`,
                data: [ clientSettings ]
            });
        }
    })
}