import { useTranslation } from "react-i18next";

interface Props { }

function ItemsNotFound(props: Props) {
    const { t } = useTranslation();

    return (
        <p
            style={{ 
                textAlign: 'center', 
                fontSize: 16,
                color: 'var(--theme-palette-fg-tertiary)',
                padding: 8,
            }}
        >
            {t("validation:itemsNotFound")}
        </p>
    )
}

export default ItemsNotFound;