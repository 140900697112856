import { useQuery } from 'react-query';

import { useHttpClient } from 'shared/http/HttpClientProvider';
import { isCustomFiled } from 'shared/hooks/useMappedCustomFields';
import { firstToUpper } from 'shared/utils/strings';
import i18next from 'modules/core/i18n/i18n';

export function useGetFilterFields() {
  const http = useHttpClient();

  return useQuery({
    queryKey: ['useGetFilterFields/get', http],
    queryFn: () => {
      return http.request({
        method: 'GET',
        url: 'Directory/searchfilterFields'
      }).then(response => {
        const { items } = response.data;

        return items?.map((item: string) => ({
          key: firstToUpper(item), // XML requires PascalCase for BE side
          text: isCustomFiled(item) ? item : i18next.t(`common:${item}`),
        }))
      })
    },
  })
}