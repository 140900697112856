import { lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Signin from '../modules/feature/signin/SignIn';
import AuthCallback from '../modules/core/auth/components/AuthCallback';
import Page from './page/Page';
import SuspenseWrapper from './page/SuspenseWrapper';
import AppBootstrap from '../modules/core/app-bootstrap/AppBootstrap';
import AppContainer from '../modules/core/app-bootstrap/AppContainer';
import Calendars from '../modules/feature/calendars/Calendars';
import Page404 from '../modules/error/components/page-404/Page404';
import PermissionCheckerGuard from './guards/PermissionCheckerGuard';
import {
  selectPermissionCalendarsView,
  selectPermissionCannedResponsesView,
  selectPermissionChatSettingsView,
  selectPermissionContactsView,
  selectPermissionEmailTemplatesView,
  selectPermissionQueuesView,
  selectPermissionReportsView,
  selectPermissionTaskSchedulerView,
  selectPermissionUsersView,
  selectPermissionNRCView,
  selectPermissionProfilesView,
  selectPermissionRecordingsView,
  selectPermissionSkillsView,
} from '../modules/core/auth/types/permission';
import AccessDenied from '../modules/error/components/access-denied/AccessDenied';
import BrandSignin from '../modules/feature/signin/BrandSignin';
import GoogleAuthCallback from '../modules/core/auth/components/GoogleAuthCallback';
import RingcentralAuthCallback from '../modules/core/auth/components/RingcentralAuthCallback';
const Dashboard = lazy(() => import('../modules/feature/dashboard/Dashboard'));
const Users = lazy(() => import('../modules/feature/users/Users'));
const Queues = lazy(() => import('../modules/feature/queues/Queues'));
const EmailTemplates = lazy(() => import('../modules/feature/email/EmailTemplates'));
const ChatSettings = lazy(() => import('../modules/feature/chat/ChatSettings'));
const Tasks = lazy(() => import('../modules/feature/tasks/Tasks'));
const Contacts = lazy(() => import('../modules/feature/contacts'));
const DirectoryManagement = lazy(() => import('../modules/feature/directory-management'));
const Reports = lazy(() => import('../modules/feature/reports/Reports'))
const Profiles = lazy(() => import('../modules/feature/profiles/Profiles'));
const Skills = lazy(() => import('../modules/feature/skills/Skills'));
const NotReadyReasonCodes = lazy(() => import('../modules/feature/not-ready-reason-codes/NotReadyReasonCodes'));
const CannedResponses = lazy(() => import('../modules/feature/canned-responses/CannedResponses'));
const Recordings = lazy(() => import('../modules/feature/recordings/Recordings'));

interface Props { }

function AppRoutes(props: Props) {
    return (
        <Routes>
            <Route path='' element={<AppBootstrap> <Outlet /> </AppBootstrap>}>
                <Route path="pages" element={<AppContainer> <Outlet /> </AppContainer>}>
                    <Route
                      path='home'
                      element={(
                        <SuspenseWrapper>
                          <Page title='home' child={<Dashboard />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='users'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionUsersView} successElement={<Page title='userManagement' child={<Users />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='queues'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionQueuesView} successElement={<Page title='queues' child={<Queues />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='email'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionEmailTemplatesView} successElement={<Page title='emailTemplates' child={<EmailTemplates />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='chat'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionChatSettingsView} successElement={<Page title='chatSettings' child={<ChatSettings />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='tasks'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionTaskSchedulerView} successElement={<Page title='taskScheduler' child={<Tasks />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='calendars'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionCalendarsView} successElement={<Page title='calendars' child={<Calendars />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='contacts'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionContactsView} successElement={<Page title='contacts' child={<Contacts />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='directory'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionContactsView} successElement={<Page title='directoryManagement' child={<DirectoryManagement />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='reports'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionReportsView} successElement={<Page title='reports' child={<Reports />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='profiles'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionProfilesView} successElement={<Page title='profiles' child={<Profiles />} />} failureElement={<AccessDenied />} />
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='skills'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionSkillsView} successElement={<Page title='skills' child={<Skills />} />} failureElement={<AccessDenied />}/>
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='nrc'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionNRCView} successElement={<Page title='nrrTitle' child={<NotReadyReasonCodes />} />} failureElement={<AccessDenied />}/>
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='recordings'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionRecordingsView} successElement={<Page title='recordings' child={<Recordings />} />} failureElement={<AccessDenied />}/>
                        </SuspenseWrapper>
                      )}
                    />
                    <Route
                      path='canned-responses'
                      element={(
                        <SuspenseWrapper>
                          <PermissionCheckerGuard permissionChecker={selectPermissionCannedResponsesView} successElement={<Page title='cannedResponses' child={<CannedResponses />} />} failureElement={<AccessDenied />}/>
                        </SuspenseWrapper>
                      )}
                    />
                    <Route path='' element={<Navigate to={'home'} replace />} />
                </Route>
                <Route path='auth'>
                    {/* <Route path="signin" element={<AuthInitializer />} /> */}
                    {/* <Route path="signout" element={<div>Signout</div>} /> */}
                    <Route path="google/callback" element={<GoogleAuthCallback />} />
                    <Route path="rcimport/callback" element={<RingcentralAuthCallback />} />
                    <Route path="callback" element={<AuthCallback />} />
                </Route>
                {/* <Route path="auth/signin" element={<AuthInitializer />} />
                <Route path="auth/callback" element={<AuthCallback />} /> */}
                <Route path='' element={<Navigate to="pages" replace />} />
            </Route>
            <Route path="signin">
                <Route index element={<Signin />} />
                <Route path=':brandId' element={<BrandSignin />} />
            </Route>
            <Route path='*' element={<Page404 />} />
        </Routes>
    )
}

export default AppRoutes;