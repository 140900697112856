import { Fragment, PropsWithChildren } from "react";
import Loader from "../../common/components/loader/Loader";
import QueryErrorViewer from "../../error/components/error-viewer/QueryErrorViewer";
import { useClientInitialized } from "./api";

interface Props {}

function ClientInitializer(props: PropsWithChildren<Props>) {
    const { isLoading, isError, isSuccess, error } = useClientInitialized()

    return (
        <Fragment>
            {isLoading && <Loader />}
            {isError && <QueryErrorViewer error={error}  />}
            {isSuccess && props.children}
        </Fragment>
    )
}

export default ClientInitializer