import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryOptions, QueryResult } from "../../../../shared/types/query";
import { isNotEmptyString } from "../../../../shared/validators/common";
import { Navigator } from "../../../common/types/navigator";
import { parserNavigator } from "../../../common/utils/parserNavigator";
import { ClientSettings, clientSettingsParser } from "../types/client-settings";

export const KEY_USERS_GET_DIRECTORY_FILTERS = 'users/getClientSettings';

export function useGetClientSettings(userId?: string, options?: QueryOptions<Navigator<ClientSettings>>): QueryResult<Navigator<ClientSettings>> {
    const http = useHttpClient();

    return useQuery({
        ...options,
        queryKey: [KEY_USERS_GET_DIRECTORY_FILTERS, http, userId],
        queryFn: () => {
            return http.request({
                method: 'GET',
                url: `Users/${userId}/settings?clientTypes=0:1`
            }).then(response => parserNavigator.fromJson(response.data, clientSettingsParser.fromJson))
        },
        enabled: isNotEmptyString(userId),
    })
}