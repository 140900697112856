import { AxiosResponse } from "axios";
import { MutationOptions, MutationResult, QueryOptions, QueryResult } from "../../../../shared/types/query";
import { Navigator } from "../../../common/types/navigator";
import { Session } from "../types/session";
import { useGetAllSessions } from "./useGetAllSessions";
import { usePostNewSession } from "./usePostNewSession";
import { useReauthenticatedSession } from "./useReauthenticatedSession";
import { useRenewSession } from "./useRenewSession";

export interface SessionsApi {
    useGetAllSessions(options?: QueryOptions<Navigator<Session>>): QueryResult<Navigator<Session>, any>;
    usePostNewSession(options?: MutationOptions<void, AxiosResponse<any>>): MutationResult<void, AxiosResponse<any>>;
    useReauthenticatedSession(options?: MutationOptions<void, AxiosResponse<any>>): MutationResult<void, AxiosResponse<any>>;
    useRenewSession(options?: QueryOptions<AxiosResponse<any>>): QueryResult<AxiosResponse<any>>;
}

const sessionsApi: SessionsApi = {
    useGetAllSessions,
    usePostNewSession,
    useReauthenticatedSession,
    useRenewSession
}

export default sessionsApi;