import { FromJsonFn } from "../../../shared/types/parser";
import { isNullOrUndefined } from "../../../shared/validators/common";
import { Navigator } from "../types/navigator";

export interface NavigatorParser {
    fromJson<T>(json: Record<string, any>, itemParser: FromJsonFn<T>): Navigator<T>;
}

export const parserNavigator: NavigatorParser = {
    fromJson: (json, parser) => ({
        items: isNullOrUndefined(json['items'])
            ? []
            : (json['items'] as any[]).map(item => parser(item)),

        page: json['.page'],
        pageSize: json['.pageSize'],
        pageStart: json['.pageStart'],
        pageEnd: json['.pageEnd'],
        itemCount: json['.itemCount'],
        pageCount: json['.pageCount'],
        firstPage: json['.firstPage'],
        previousPage: json['.previousPage'],
        nextPage: json['.nextPage'],
        lastPage: json['.lastPage'],
    })
}