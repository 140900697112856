import { ProfileImage } from "./profile-image";
import { User } from "./user";
import { CommunicationChannel } from "./communication-channel";

export interface UserFormData {
    id?: string;
    userName: string;
    fullName: string;
    preferredExtension: string;
    email: string;
    externalId: string;
    profileImage: ProfileImage | null;
    directCallPriorityLevel: number;
    isEnabled: boolean;
    isLockedOut: boolean;
    userRole: string;
    recordOutgoingCalls: boolean;
    allowTemporaryQueueAssign: boolean;
    password: string;
    confirmPassword: string;
    communicationChannelVoice: boolean;
    communicationChannelChat: boolean;
    canSetAvailability: boolean;
    configurationProfileId?: number;
}

export interface UserFormDataParser {
    empty(): UserFormData;
    fromUser(user: User): UserFormData;
    toRequestData(value: UserFormData): Record<string, any>;
}

export const userFormDataParser: UserFormDataParser = {
    empty: () => {
        return {
            id: '',
            userName: '',
            fullName: '',
            preferredExtension: '',
            email: '',
            externalId: '',
            profileImage: null,
            directCallPriorityLevel: 0,
            isEnabled: true,
            isLockedOut: false,
            userRole: '',
            recordOutgoingCalls: false,
            allowTemporaryQueueAssign: false,
            password: '',
            confirmPassword: '',            
            communicationChannelChat: true,
            communicationChannelVoice: true,
            canSetAvailability: true,
        }
    },
    fromUser: (user) => {
        return {
            id: user.id,
            userName: user.userName,
            fullName: user.fullName,
            preferredExtension: user.preferredExtension,
            email: user.email,
            externalId: user.externalId,
            profileImage: user.profileImage,
            directCallPriorityLevel: user.directCallPriorityLevel,
            isEnabled: user.isEnabled,
            isLockedOut: user.isLockedOut,
            userRole: user.userRole?.id || '',
            recordOutgoingCalls: user.recordOutgoingCalls,
            allowTemporaryQueueAssign: user.allowTemporaryQueueAssign,
            password: '',
            confirmPassword: '',
            communicationChannelChat: user.communicationChannelChat,
            communicationChannelVoice: user.communicationChannelVoice,
            canSetAvailability: user.canSetAvailability,
            configurationProfileId: user.configurationProfileId ?? null
        }
    },
    toRequestData: (value) => {
        let tokenArray: Array<number> | null = null;
        if(value.password) {
            const byteArray = new Uint8Array(value.password.length * 2);
            for (var i = 0; i < value.password.length; i++) {
                byteArray[i * 2] = value.password.charCodeAt(i) // & 0xff;
                byteArray[i * 2 + 1] = value.password.charCodeAt(i) >> 8 // & 0xff;
            }
            tokenArray = Array.from(byteArray);
        }

        var communicationChannels = [];

        if(value.communicationChannelVoice) {
            communicationChannels.push(CommunicationChannel.Voice);
        }

        if(value.communicationChannelChat) {
            communicationChannels.push(CommunicationChannel.Chat);
        }

        return {
            id: value.id,
            userName: value.userName,
            fullName: value.fullName,
            preferredExtension: value.preferredExtension.replace(/\s/g, ''),
            email: value.email,
            externalId: value.externalId,
            profileImage: value.profileImage,
            directCallPriorityLevel: value.directCallPriorityLevel,
            isEnabled: value.isEnabled,
            isLockedOut: value.isLockedOut,
            userRole: value.userRole,
            recordOutgoingCalls: value.recordOutgoingCalls,
            allowTemporaryQueueAssign: value.allowTemporaryQueueAssign,
            tokenArray: tokenArray,
            channelAvailability: communicationChannels,
            canSetAvailability: value.canSetAvailability,
            configurationProfileId: value.configurationProfileId || null
        }
    }
}