import ErrorModal from "./ErrorModal";

interface Props {
    error: any;
    reset: () => void;
}

function MutationErrorViewer(props: Props) {
    return (
        <ErrorModal error={props.error} onDismiss={props.reset} />
    )
}

export default MutationErrorViewer