import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import Provider, { createProviderToken, useProvider } from "../../../shared/provider";
import QueryResultHandler from "../../common/components/handlers/QueryResultHandler";
import { useOidcUserManager } from "../auth/components/OidcUserManagerProvider";
import { useBrand } from "../configuration/components/BrandProvider";
import discoveryApi from "./api";
import { ApiServerInfo } from "./types/api-server-info";

const apiServerInfoProviderToken = createProviderToken<ApiServerInfo>('apiServerInfoProvider');

export function useApiServerInfo(): ApiServerInfo {
    return useProvider(apiServerInfoProviderToken);
}


interface Props {
    children?: ReactNode;
}

function ApiServerInfoProvider(props: Props) {
    const navigate = useNavigate();
    const brandProvider = useBrand();
    const oidcUserManager = useOidcUserManager()
    const getApiServerInfoResult = discoveryApi.useGetApiServerInfo({
        onError: (err) => {
            brandProvider.removeBrand()
            oidcUserManager.removeUser().then(_ => navigate("/signin"))
        }
    })

    return (
        <QueryResultHandler result={getApiServerInfoResult}>
            {({ data }) => (
                <Provider provide={apiServerInfoProviderToken} value={data}>
                    {props.children}
                </Provider>
            )}
        </QueryResultHandler>
    )
}

export default ApiServerInfoProvider