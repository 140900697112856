import { Field, FieldArray, FieldArrayRenderProps, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DailyIntervals from "./daily-intervals/DailyIntervals";
import Name from "./name/Name";
import { Calendar } from "../../types/calendar";
import AlwaysClosedOn from "./alwaysClosedOn/AlwaysClosedOn";
import FormActionButtons from "../../../../common/components/actions/form-action-buttons/FormActionButtons";
import { getCalendarFormValidationScheme } from "../../utils/formValidationScheme";

interface Props {
    initialState?: Calendar;
    disabledActions?: boolean;
    hideForm?: boolean;
    onSubmit: (value: Calendar, helpers: FormikHelpers<Calendar>) => void;
}

function CalendarForm(props: Props) {
    const { initialState } = props;
    const { t } = useTranslation();

    const initialValues = useMemo(() => {
        return initialState || {
            id: '',
            name: '',
            alwaysCloseOn: [],
            dailyIntervals: [],
        }
    }, [initialState])

    const validationSchema = useMemo(() => getCalendarFormValidationScheme(t), [t]);

    const resetCallback = useCallback((values: Calendar, helpers: FormikHelpers<Calendar>) => {
        helpers.setValues(initialValues, true);
    }, [initialValues])


    return (
        <Formik<Calendar>
            validateOnMount={false}
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={props.onSubmit}
            onReset={resetCallback}
            enableReinitialize={true}
        >
            <Form style={{ display: props.hideForm ? 'none' : 'block' }}>
                <Field name="name">
                    {(name: FieldProps<string, Calendar>) => <Name {...name} />}
                </Field>
                <FieldArray name="dailyIntervals" validateOnChange={false}>
                    {(dailyIntervals: FieldArrayRenderProps) => <DailyIntervals {...dailyIntervals} />}
                </FieldArray>
                <FieldArray name="alwaysCloseOn" validateOnChange={false}>
                    {(alwaysCloseOn: FieldArrayRenderProps) => <AlwaysClosedOn {...alwaysCloseOn} />}
                </FieldArray>

                <FormActionButtons />
            </Form>
        </Formik>
    )
}

export default CalendarForm;