import classNames from 'classnames'
import { MouseEventHandler, ReactNode } from 'react';
import styles from './ListView.module.css'

interface Props {
    isSelected?: boolean;
    primaryText: string;
    secondaryText?: string;
    tertiaryText?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    icon?: ReactNode;
    
}

function ListViewItem(props: Props) {
    return (
        <button className={classNames(styles.list_view_item, { [styles.list_view_item_selected]: props.isSelected })} onClick={props.onClick}>
            <div>
                 {props.icon}
            </div>
            <div className={styles.item_text_container}>
                <span className={classNames(styles.item_text, styles.item_primary_text)}>{props.primaryText}</span>
                {props.secondaryText && <span className={classNames(styles.item_text, styles.item_secondary_text)}>{props.secondaryText}</span>}
                {props.tertiaryText && <span className={classNames(styles.item_text, styles.item_secondary_text)}>{props.tertiaryText}</span>}
            </div>
        </button>
    )
}

export default ListViewItem