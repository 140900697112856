import { DefaultButton } from '@fluentui/react/lib/Button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConnecsyLogo from '../../common/components/logo/Logo';
import { useBrand } from '../../core/configuration/components/BrandProvider';
import { BrandAuthConfig } from '../../core/configuration/types/brand';
import styles from './Signin.module.css';

interface Props {
    brand?: BrandAuthConfig
}

function SigninForm(props: Props) {
    const { brand } = props;
    const [t] = useTranslation();

    const navigate = useNavigate();
    const { setBrand } = useBrand();

    const _login = useCallback((brand: BrandAuthConfig) => {
        setBrand(brand.bid)
        navigate('/pages/home');
    },
        [setBrand, navigate]
    );

    return (
        <div className={styles.signin_container}>
            <div className={styles.signin_content}>
                <ConnecsyLogo width="152px" height="auto" />

                {brand && (
                    <div className={styles.signin_buttons_container}>
                        <DefaultButton
                            key={brand.bid}
                            className={styles.signin_button}
                            onClick={() => _login(brand)}
                        >
                            {t("common:adminLogin")}
                        </DefaultButton>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SigninForm;
