import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { useHttpClient } from '../../../../shared/http/HttpClientProvider';
import { MutationOptions, MutationResult } from '../../../../shared/types/query';

interface DeletePermanentlyMutationParams {
  id: string;
  hardDelete?: boolean;
}

export function useDeleteUser(options?: MutationOptions<DeletePermanentlyMutationParams, AxiosResponse>): MutationResult<DeletePermanentlyMutationParams, AxiosResponse> {
  const http = useHttpClient();

  return useMutation({
    ...options,
    mutationKey: ['users/delete', http],
    mutationFn: ({id, hardDelete = false}) => {
      return http.request({
        method: 'delete',
        url: `Users/${id}`,
        params: {
          hardDelete,
        }
      })
    },
  })
}
