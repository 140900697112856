import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";

export function useUnlockUser(options?: MutationOptions<string, AxiosResponse<any>>): MutationResult<string, AxiosResponse<any>> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['users/unlock', http],
        mutationFn: (userId: string) => {
            return http.request({
                method: 'POST',
                url: `Users/${userId}/unlock`
            })
        }
    })
}