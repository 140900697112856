import { QueryOptions, QueryResult } from '../../../../shared/types/query';
import { ApiServerInfo } from '../types/api-server-info';
import { AuthProviderConfig } from '../types/auth-provider-config';
import { useGetApiServerInfo } from "./useGetApiServerInfo";
import { useGetAuthProviderConfig } from "./useGetAuthProviderConfig";

export interface DiscoveryApi {
    useGetApiServerInfo(options?: QueryOptions<ApiServerInfo>): QueryResult<ApiServerInfo>;
    useGetAuthProviderConfig(): QueryResult<AuthProviderConfig>;
}

const discoveryApi: DiscoveryApi = {
    useGetApiServerInfo,
    useGetAuthProviderConfig,
}

export default discoveryApi;