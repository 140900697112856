import { ReactNode, useMemo } from "react";
import HttpClientProvider, { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { useBrandAuthConfig } from "../../configuration/components/BrandAuthConfigProvider";
import { getBasicAuthHeader, getDiscoveryBaseUrl } from "../utils/utils";

interface Props {
    children?: ReactNode;
}

function DiscoveryBaseUrlWithBasicAuthHeaderInterceptor(props: Props) {
    const brandAuthConfig = useBrandAuthConfig();
    const http = useHttpClient();

    const _httpClient = useMemo(() => {
        const _http = http.clone();

        _http.addRequestInterceptor(config => {
            config.baseURL = getDiscoveryBaseUrl(brandAuthConfig);

            const headers = config.headers || {};
            headers['Authorization'] = getBasicAuthHeader(brandAuthConfig)
            config.headers = headers;

            return config;
        });

        return _http;
    }, [http, brandAuthConfig])


    return (
        <HttpClientProvider httpClient={_httpClient}>
            {props.children}
        </HttpClientProvider>
    )
}

export default DiscoveryBaseUrlWithBasicAuthHeaderInterceptor;