import React, { Suspense, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/Routes';
import './modules/core/i18n/i18n';
import ThemeProvider from './theme/ThemeProvider';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Toaster } from 'react-hot-toast';
import BrandProvider from './modules/core/configuration/components/BrandProvider';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import HttpClientProvider from './shared/http/HttpClientProvider';
import { createHttpClient } from './shared/http/http';
import ReactQueryClientProvider from './modules/core/app-bootstrap/QueryClientProvider';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
initializeIcons();

console.log(`%cVERSION: ${process.env.REACT_APP_VERSION}`, "font-size: 2rem;")

function App(): React.ReactElement {
  const http = useMemo(() => createHttpClient(), [])

  return (
    <Suspense fallback={<p>...</p>}>
      <ReactQueryClientProvider>
        <BrowserRouter>
          <ThemeProvider>
            <HttpClientProvider httpClient={http}>
                <BrandProvider>
                  <AppRoutes />
                  <Toaster />
                </BrandProvider>
            </HttpClientProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ReactQueryClientProvider>
    </Suspense>
  )
}
export default App;
