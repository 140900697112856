import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";
import { Queue } from "../../queues/types/queue";

export function useSetAssignedQueues(options?: MutationOptions<[string, Array<Queue>], AxiosResponse<any>>): MutationResult<[string, Array<Queue>], AxiosResponse<any>> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['users/setAssignedQueues', http],
        mutationFn: ([userId, queues]: [string, Array<Queue>]) => {
            return http.request({
                method: 'POST',
                url: `Users/${userId}/assignedqueues`,
                data: queues.map(item => item.id)
            })
        }
    })
}