import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../common/components/loader/Loader";
import QueryErrorViewer from "../../../error/components/error-viewer/QueryErrorViewer";
import { getRingcentralContactConfigParser } from "../../../feature/tasks/types/task-config/ringcentral-contact-task-config";
import { taskFormDataParser } from "../../../feature/tasks/types/task-form-data";
import { createOidcUserManagerFromSettings } from "../utils/utils";

interface Props { }

function RingcentralAuthCallback(props: Props) {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { isError, error } = useQuery({
        queryKey: ['auth/ringcentral/callback', navigate, searchParams],
        retry: 0,
        queryFn: async () => {
            const state = searchParams.get('state');
            if (!state) throw new Error('State not found');
            const key = `oidc.${state}`;
            const settings = sessionStorage.getItem(key);
            if (!settings) throw new Error('Ringcentral auth settings not found');
            const parsedSettings = JSON.parse(settings);
            const userManager = createOidcUserManagerFromSettings({
                authorityUri: parsedSettings.authority,
                clientId: parsedSettings.client_id,
                clientSecret: parsedSettings.client_secret,
                redirectUri: parsedSettings.redirect_uri,
            })
            const user = await userManager.signinCallback();
            if (!user) throw new Error('User not found');

            const formValues = (user.state as any).values;
            const from = (user.state as any).location;

            await userManager.clearStaleState();
            await userManager.removeUser();

            if (formValues && from) {
                const parsedFormValues = taskFormDataParser.fromJson(formValues, getRingcentralContactConfigParser());

                navigate(from, { state: {
                    ...parsedFormValues,
                    taskConfig: {
                        ...parsedFormValues.taskConfig,
                        accessToken: user.access_token,
                        expiresAt: dayjs().add(user.expires_in ?? 60, 'second').toISOString(),
                        refreshToken: user.refresh_token!,
                        refreshTokenExpiresAt: (user as any).refresh_token_expires_in
                            ? dayjs().add((user as any).refresh_token_expires_in, 'second').toISOString()
                            : dayjs().add(7, 'day').toISOString()
                    }
                } })
            }

        }
    })

    if (isError) return <QueryErrorViewer error={error} />
    return <Loader />
}

export default RingcentralAuthCallback