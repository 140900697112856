import { FromJsonParser } from "../../../../shared/types/parser";

export enum UserRoles {
    SystemAdmin = 'SystemAdmin',
    Supervisor = "Supervisor",
    Attendant = 'Attendant',
}

export interface UserRole {
    id: UserRoles;
    name: string;
    description: string;
}


export interface UserRoleParser extends FromJsonParser<UserRole> {}

export const userRoleParser: UserRoleParser = {
    fromJson: (json) => {
        return {
            id: json['id'] ?? '',
            name: json['name'] ?? json['roleName'] ?? '',
            description: json['description'] ?? '',
        }
    }
}