import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import OidcUserCheckerGuard from '../../../routes/guards/OidcUserCheckerGuard';
import DiscoveryBaseUrlWithBearerAuthHeaderInterceptor
  from '../http/components/DiscoveryBaseUrlWithBearerAuthHeaderInterceptor';
import AuthInterceptor from '../http/components/AuthInterceptor';
import AppSkeleton from '../../common/components/app-skeleton/AppSkeleton';
import ApiServerInfoProvider from '../discovery/ApiServerInfoProvider';
import SessionManager from '../session/SessionManager';
import ServerConfigurationProvider from '../configuration/components/ServerConfigurationProvider';
import UserDataProvider from '../auth/components/UserDataProvider';
import UserAuth from '../auth/components/AuthInitializer';
import SubscriptionManager from '../subscription/SubscriptionManager';
import ClientInitializer from '../client-initializer/ClientInitializer';
import TelerikVersionProvider from '../../feature/reports/components/TelerikVersionProvider';

interface Props {
  children?: ReactNode
}

function AppContainer(props: Props) {
  return (
    <UserAuth>
      <OidcUserCheckerGuard
        failureElement={<Navigate to="/signin" replace/>}
        successElement={
          <DiscoveryBaseUrlWithBearerAuthHeaderInterceptor>
            <ApiServerInfoProvider>
              <AuthInterceptor>
                <SessionManager>
                  <SubscriptionManager>
                    <ServerConfigurationProvider>
                      <UserDataProvider>
                        <TelerikVersionProvider>
                          <ClientInitializer>
                            <AppSkeleton>
                              {props.children}
                            </AppSkeleton>
                          </ClientInitializer>
                        </TelerikVersionProvider>
                      </UserDataProvider>
                    </ServerConfigurationProvider>
                  </SubscriptionManager>
                </SessionManager>
              </AuthInterceptor>
            </ApiServerInfoProvider>
          </DiscoveryBaseUrlWithBearerAuthHeaderInterceptor>
        }
      />
    </UserAuth>
  )
}

export default AppContainer;