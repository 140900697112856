import { ReactElement } from "react";
import { useBrand } from "../../modules/core/configuration/components/BrandProvider";
import { useRxStateValue } from "../../shared/rxstate";

interface Props {
    successElement: ReactElement;
    failureElement: ReactElement;
}

function BrandCheckerGuard(props: Props) {
    const brandProvider = useBrand();
    const brand = useRxStateValue(brandProvider.brand);

    if (brand) return props.successElement

    return props.failureElement;
}

export default BrandCheckerGuard;