import { ReactElement } from "react";
import { useOidcUser } from "../../modules/core/auth/components/OidcUserProvider";
import { useRxStateValue } from "../../shared/rxstate";

interface Props {
    successElement: ReactElement;
    failureElement: ReactElement;
}

function OidcUserCheckerGuard(props: Props) {
    const oidcUser = useRxStateValue(useOidcUser());

    if (oidcUser) return props.successElement

    return props.failureElement;
}

export default OidcUserCheckerGuard;