import { useQuery } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { QueryOptions, QueryResult } from "../../../../shared/types/query";
import { isNotEmptyString } from "../../../../shared/validators/common";
import { Navigator } from "../../../common/types/navigator";
import { parserNavigator } from "../../../common/utils/parserNavigator";
import { CustomField, customFieldParser } from "../types/custom-field";

export const KEY_USERS_GET_CUSTOM_FIELDS = 'users/getCustomFields';

export function useGetCustomFields(userId?: string, options?: QueryOptions<Navigator<CustomField>>): QueryResult<Navigator<CustomField>> {
    const http = useHttpClient();

    return useQuery({
        ...options,
        queryKey: [KEY_USERS_GET_CUSTOM_FIELDS, http, userId],
        queryFn: () => {
            return http.request({
                method: 'GET',
                url: `Users/${userId}/customfields`
            }).then(response => parserNavigator.fromJson(response.data, customFieldParser.fromJson))
        },
        enabled: isNotEmptyString(userId)
    })
}