import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBrand } from "../../core/configuration/components/BrandProvider";
import { BrandAuthConfig } from "../../core/configuration/types/brand";
import SigninForm from "./SigninForm";
import { getAuthConfig } from "./utils/getConfig";
import ErrorMessage from "modules/error/components/error-message/ErrorMessage";
import { useTranslation } from "react-i18next";

function BrandSignin() {
    const { t } = useTranslation();
    const { brandId } = useParams();
    const navigate = useNavigate();
    const brandState = useBrand();

    const [brand, setBrand] = useState<BrandAuthConfig>();
    const [error, setError] = useState<boolean>();

    useEffect(() => {        
        if (brandId && Number.isInteger(+brandId)) {
            const { data, error: parseConfigError } = getAuthConfig(brandId)            

            if (data) {
                setBrand(data);
                return;
            }

            if (parseConfigError) {                
                setError(true)
                return
            }
        }

        navigate("/signin");
    }, [brandId, navigate, brandState])

    if (error) {
        console.error('Failed to parse config');
        
        return <ErrorMessage message={t("errors:commonErr")} />
    }

    return (
        <Fragment>
            {!error && brand && <SigninForm brand={brand} />}
        </Fragment>
    )
}

export default BrandSignin;
