import { ReactElement } from "react";
import { QueryResult } from "../../../../shared/types/query";
import QueryErrorViewer from "../../../error/components/error-viewer/QueryErrorViewer";
import Loader from "../loader/Loader";

interface Props<T> {
    result: QueryResult<T, any>;
    children?: (result: { data: T }) => ReactElement;
}

function QueryResultHandler<T>(props: Props<T>) {
    const { result, children } = props;
    const { isLoading, isFetching, isError, isSuccess, data, error } = result;
    
    if(isLoading || isFetching) return <Loader />
    if(isError && error) return <QueryErrorViewer error={error} />
    if(isSuccess && data && children) return children({ data });
    return null;
}

export default QueryResultHandler