import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { parserCommonError } from "../../utils/parserCommonError";
import ErrorModal from "./ErrorModal";
import styles from './ErrorViewer.module.css';

interface Props {
    error: any;
}

function ErrorViewer(props: Props) {
    const { error } = props;
    const { t } = useTranslation();

    const commonError = useMemo(() => parserCommonError.parseError(error), [error])

    const [modalError, setModalError] = useState<any>();
    const openDialog = useCallback(() => {
        setModalError(commonError);
    }, [commonError])

    const closeDialog = useCallback(() => {
        setModalError(null);
    }, [])

    if (commonError && commonError.message)
        return (
            <div>
                <span className={styles.error_message}>{commonError.message}.</span>
                <a href="#seeDetails" className={styles.error_see_details_action} onClick={openDialog}>{t("common:seeDetails")}</a>
                <ErrorModal 
                    error={modalError}
                    onDismiss={closeDialog}
                />
            </div>
        )
    return null;
}

export default ErrorViewer