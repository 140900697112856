import { useCreateUser } from "./useCreateUser";
import { useDeleteAssignedQueues } from "./useDeleteAssignedQueues";
import { useDeleteCustomField } from "./useDeleteCustomField";
import { useDeleteUser } from "./useDeleteUser";
import { useRestoreUser } from "./useRestoreUser";
import { useGetAllUserRoles } from "./useGetAllUserRoles";
import { useGetAllUsers } from "./useGetAllUsers";
import { useGetAssignedQueues } from "./useGetAssignedQueues";
import { useGetClientSettings } from "./useGetClientSettings";
import { useGetCustomFields } from "./useGetCustomFields";
import { useGetUser } from "./useGetUser";
import { useSetAssignedQueues } from "./useSetAssignedQueues";
import { useSetClientSettings } from "./useSetClientSettings";
import { useSetCustomFields } from "./useSetCustomFields";
import { useUnlockUser } from "./useUnlockUser";
import { useUpdateUser } from "./useUpdateUser";
import { useGetFilterFields } from "./useGetFilterFields";


const userApi = {
    useGetAllUsers,
    useGetUser,
    useCreateUser,
    useUpdateUser,
    useDeleteUser,
    useRestoreUser,
    useUnlockUser,
    useGetAssignedQueues,
    useSetAssignedQueues,
    useGetCustomFields,
    useSetCustomFields,
    useGetClientSettings,
    useSetClientSettings,
    useGetAllUserRoles,
    useDeleteAssignedQueues,
    useDeleteCustomField,
    useGetFilterFields,
}

export default userApi;