import { User } from 'oidc-client-ts';
import { ApiServerInfo } from '../../discovery/types/api-server-info';
import { BrandAuthConfig } from 'modules/core/configuration/types/brand';

export function getRestApiBaseUrl(brandAuthConfig: BrandAuthConfig, apiServerInfo: ApiServerInfo): string {
    return `${apiServerInfo.apiServerUri}/api/v${brandAuthConfig.apiVersion}/sessions`;
}

export function getRestApiBaseUrlWithSessionId(brandAuthConfig: BrandAuthConfig, apiServerInfo: ApiServerInfo, sessionId: string): string {
    return `${getRestApiBaseUrl(brandAuthConfig, apiServerInfo)}/${sessionId}`;
}

export function getDiscoveryBaseUrl(brandAuthConfig: BrandAuthConfig): string {
    return `${brandAuthConfig.dbu}/api/v${brandAuthConfig.apiVersion}`
}

export function getBasicAuthHeader(brandAuthConfig: BrandAuthConfig): string {
    return `Basic ${window.btoa(brandAuthConfig.aid + ':' + brandAuthConfig.art)}`;
}

export function getBearerAuthHeader(oidcUser: User): string {
    return `Bearer ${oidcUser.access_token}`;
}

export function getAccessToken(oidcUser: User): string {
    return oidcUser.access_token;
}
