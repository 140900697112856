import classNames from "classnames";
import { useState } from "react";
import { useCallback } from "react";
import { ReactNode } from "react";
import Appbar from "../appbar/Appbar";
import Menu from "../menu/Menu";
import styles from "./AppSkeleton.module.css";

interface Props {
  children?: ReactNode;
}

function AppSkeleton(props: Props) {
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const toggleMenu = useCallback(() => {
    setIsOpenMenu(v => !v);
  }, [])

  return (
    <div className={styles.app_skeleton}>
      <header>
        <Appbar onClickMenuButton={toggleMenu} />
      </header>
      <aside className={classNames({ [styles.open_menu]: isOpenMenu })}>
        <nav>
          <Menu onClickMenuItem={toggleMenu} />
        </nav>
      </aside>
      <main>
        {props.children}
      </main>
    </div>
  )
}

export default AppSkeleton;
