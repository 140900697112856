import { PropsWithChildren } from "react";
import { useQueryClient } from "react-query";
import Loader from "../../common/components/loader/Loader";
import ErrorViewer from "../../error/components/error-viewer/ErrorViewer";
import { useGetAllSubscriptions, usePostNewSubscription } from "./api";
import SubscriptionProvider from "./SubscriptionProvider";

interface Props {}

function SubscriptionManager(props: PropsWithChildren<Props>) {
    const queryClient = useQueryClient();

    const getAllSubscriptionsResult = useGetAllSubscriptions({
        onSuccess: (result) => {
            if(!result.items || !result.items.length) {
                createNewSubscriptionResult.mutate(undefined);
            }
        }
    })

    const createNewSubscriptionResult = usePostNewSubscription({
        onSuccess: (result) => {
            queryClient.invalidateQueries(['subscriptions/getAll'])
        }
    })

    if(getAllSubscriptionsResult.data?.items && getAllSubscriptionsResult.data.items.length > 0) {
        const subscription = getAllSubscriptionsResult.data.items[0];
        return <SubscriptionProvider subscription={subscription}> {props.children} </SubscriptionProvider>
    }

    if(getAllSubscriptionsResult.isError) return <ErrorViewer error={getAllSubscriptionsResult.error} />
    if(createNewSubscriptionResult.isError) return <ErrorViewer error={createNewSubscriptionResult.error} />


    return <Loader />
}

export default SubscriptionManager;