import { ReactNode, useEffect } from "react";
import Provider, { createProviderToken, useProvider } from "../../../shared/provider";
import { useRxState } from "../../../shared/rxstate";
import SessionInterceptor from "../http/components/SessionInterceptor";
import { Session } from "./types/session";
import SessionUpdater from "./SessionUpdater";
import { RxState } from "../../../shared/rxstate";


const sessionProviderToken = createProviderToken<RxState<Session>>('sessionProvider');

export function useSession(): RxState<Session> {
    return useProvider(sessionProviderToken);
}

interface Props {
    session: Session;
    children?: ReactNode;
}


function SessionProvider(props: Props) {
    const { session } = props;

    const sessionRxState = useRxState<Session>(session);

    useEffect(() => {
        sessionRxState.next(session);
    }, [session, sessionRxState]);


    return (
        <Provider provide={sessionProviderToken} value={sessionRxState}>
            <SessionInterceptor>
                <SessionUpdater>
                    {props.children}
                </SessionUpdater>
            </SessionInterceptor>
        </Provider>
    )
}

export default SessionProvider;