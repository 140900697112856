import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { timeFormats } from '../../../../shared/utils/date';
import { isEmptyString } from '../../../../shared/validators/common';

export function getCalendarFormValidationScheme(t: TFunction) {
    return yup.object({
        id: yup.string().optional(),
        name: yup.string().required(t("validation:required")),
        alwaysCloseOn: yup.array()
            .of(yup.object({
                date: yup.date().typeError(t("validation:mustBeType", { type: `date` }))
            })),
        dailyIntervals: yup.array()
            .of(yup.object({
                daysOfWeek: yup.array()
                    .of(yup.string())
                    .required()
                    .min(1, t("validation:dayOfWeekRequired")),
                start: yup.string()
                    .required(t("validation:required"))
                    .test('start_time_format', t("validation:notValidTimeFormat"), (value) => dayjs(value, timeFormats, true).isValid())
                    .when('end', (end, schema) => {
                        if (isEmptyString(end)) return schema;
                        return schema.test('start_before_end', t("validation:startDatMustBeforeEndDate"), (value: string) => {
                            return dayjs(value, timeFormats, true).isBefore(dayjs(end, timeFormats, true))
                        })
                    }),
                end: yup.string()
                    .required(t("validation:required"))
                    .test('end_time_format', t("validation:notValidTimeFormat"), (value) => dayjs(value, timeFormats, true).isValid())
            }))
            .required()
            .min(1, t("validation:dailyIntervalsRequired"))
    })
}