import { FromJsonParser } from "../../../../shared/types/parser";

export interface CustomField {
    customFieldKey: string;
    value: string;
}

export interface CustomFieldParser extends FromJsonParser<CustomField> {}

export const customFieldParser: CustomFieldParser = {
    fromJson: (json) => {
        return {
            customFieldKey: json['customFieldKey'] ?? '',
            value: json['value'] ?? '',
        }
    }
}