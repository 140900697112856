import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useHttpClient } from "../../../../shared/http/HttpClientProvider";
import { MutationOptions, MutationResult } from "../../../../shared/types/query";
import { CustomField } from "../types/custom-field";

export function useSetCustomFields(options?: MutationOptions<[string, Array<CustomField>], AxiosResponse<any>>): MutationResult<[string, Array<CustomField>], AxiosResponse<any>> {
    const http = useHttpClient();

    return useMutation({
        ...options,
        mutationKey: ['users/setCustomFields', http],
        mutationFn: ([userId, customFields]: [string, Array<CustomField>]) => {  
            return http.request({
                method: 'POST',
                url: `Users/${userId}/customfields`,
                data: customFields,
            })
        }
    })
}